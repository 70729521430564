import ReactPlayer from 'react-player/file';
import styled from '@emotion/native';

import PlayIcon from '@src/assets/icons/play.svg';
import type {Maybe} from '@src/data/generated';

type Props = {
  videoURL?: Maybe<string>;
  thumbnailURL?: Maybe<string>;
};

const NightWatch = ({videoURL, thumbnailURL}: Props) =>
  videoURL ? (
    <PlayerWrapper>
      <ReactPlayer
        url={videoURL}
        width="100%"
        controls={true}
        volume={1}
        muted={true}
        pip={true}
        height={150}
        playIcon={<PlayIcon />}
        light={
          // true,
          thumbnailURL
            ? thumbnailURL
            : 'https://static.cdn.asset.aparat.com/avt/49357784-4147-b__3410.jpg'
        }
      />
    </PlayerWrapper>
  ) : (
    <></>
  );

export default NightWatch;

const PlayerWrapper = styled.View(props => ({
  width: '100%',
  borderRadius: props.theme.roundness * 10,
  overflow: 'hidden',
}));
