import type {ForwardedRef} from 'react';
import {forwardRef, memo} from 'react';
import type {TextInputProps} from 'react-native-paper';
import {TextInput as TI} from 'react-native-paper';
import {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {useTheme} from '@emotion/react';

import {spacing} from '@src/theme/units';

const TextInput = forwardRef(
  (props: Omit<TextInputProps, 'theme'>, ref: ForwardedRef<any>) => {
    const theme = useTheme();
    return (
      <TI
        ref={ref}
        mode="outlined"
        {...props}
        textAlign={'right'}
        // @ts-ignore
        contentStyle={contentStyles}
        placeholderTextColor={theme.colors.onSurface}
        style={[customStyles(theme), props.style]}
        outlineStyle={[outlineStyle(theme), props.outlineStyle]}
      />
    );
  },
);

export default memo(TextInput);

const outlineStyle = (theme: Theme) =>
  css({
    borderRadius: spacing(theme.roundness),
    borderColor: theme.colors.surface,
  });

const customStyles = (theme: Theme) =>
  css({
    height: spacing(7),
    lineHeight: 24,
    marginTop: 16,
    backgroundColor: theme.colors.surface,
    textAlign: 'right',
    borderColor: theme.colors.surface,
  });
const contentStyles = css({
  textAlign: 'right',
});
