import {Avatar, Text} from 'react-native-paper';
import styled from '@emotion/native';

import {spacing} from '@src/theme/units';
import translations from '@src/translations';

const MafiaCard = ({name, avatar}: any) => (
  <CitizenContainer>
    <AvatarImage size={27} source={{uri: avatar}} />
    <TextContainer>
      <Title variant="labelMedium">{translations.common.mafia}</Title>
      <DText variant="labelMedium">{name}</DText>
    </TextContainer>
  </CitizenContainer>
);
export default MafiaCard;

const CitizenContainer = styled.View({
  flexDirection: 'row-reverse',
  alignItems: 'center',
});

const TextContainer = styled.View({
  marginRight: spacing(0.4),
});

const Title = styled(Text)(({theme}) => ({
  color: theme.colors.primary,
  textAlign: 'right',
}));

const DText = styled(Text)({
  textAlign: 'right',
  fontSize: 10,
});

const AvatarImage = styled(Avatar.Image)(({theme}) => ({
  backgroundColor: theme.colors.surface,
  marginRight: spacing(0.4),
}));
