import {memo} from 'react';
import {SheetManager} from 'react-native-actions-sheet';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {useAtomValue} from 'jotai';

import Button from '@src/components/atoms/Button';
import SheetsID from '@src/components/organism/Sheets/SheetsID';
import {CurrentLiveGameAtom} from '@src/components/screens/Game/store';
import {translateNumbers} from '@src/generators/translateNumbers';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

const ShootContent = () => {
  const game = useAtomValue(CurrentLiveGameAtom);

  const handleOpenModal = () => {
    SheetManager.show(SheetsID.mafiaSelectionSheet);
  };
  if (game?.status === 'voting') {
    return (
      <CButton mode="contained" onPress={handleOpenModal}>
        <ButtonText variant="titleMedium">
          {`
        ${translations.buttons.shotNow} -
        ${translations.common.day}
        ${translateNumbers(game?.currentRound ?? 0)}
        `}
        </ButtonText>
      </CButton>
    );
  } else {
    return <></>;
  }
};

export default memo(ShootContent);

const CButton = styled(Button)({
  margin: spacing(3),
  position: 'absolute',
  bottom: 0,
  width: '90%',
  maxWidth: 400,
});
const ButtonText = styled(Text)(({theme}) => ({
  color: theme.colors.background,
}));
