import Svg, {Defs, Path, RadialGradient, Rect, Stop} from 'react-native-svg';

type Props = {
  isLive?: boolean;
};

const LiveIcon = ({isLive}: Props) => (
  <Svg width="68" height="68" viewBox="0 0 68 68" fill="none">
    <circle cx="34" cy="34" r="34" fill={isLive ? '#E4C340' : '#282828'} />
    <Rect
      x="18.7"
      y="23.2713"
      width="30.6"
      height="21.4571"
      rx="5.3"
      stroke={isLive ? '#0A0A0A' : '#E4C340'}
      strokeWidth="1.4"
    />
    <Path
      d="M25.5614 36.79H28.6964V38H24.2964V30.3H25.5614V36.79ZM29.6352 30.3H30.9002V38H29.6352V30.3ZM34.3969 38L31.7239 30.3H33.1099L35.1559 36.471L37.2129 30.3H38.5879L35.9149 38H34.3969ZM40.6756 36.79H44.0856V38H39.4106V30.3H44.0306V31.51H40.6756V33.512H43.7556V34.711H40.6756V36.79Z"
      fill={isLive ? '#0A0A0A' : '#E4C340'}
    />
    <Defs>
      <RadialGradient
        id="paint0_radial_408_655"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(34 34) rotate(90) scale(34)">
        <Stop stopColor="#F4D144" stopOpacity="0.83" />
        <Stop offset="0.386903" stopColor="#F4D144" stopOpacity="0.88" />
        <Stop offset="0.727198" stopColor="#F4D144" />
        <Stop offset="1" stopColor="#F4D144" />
      </RadialGradient>
    </Defs>
  </Svg>
);

export default LiveIcon;
