import {Avatar, Text} from 'react-native-paper';
import styled from '@emotion/native';

import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type UserProps = {
  name?: string;
  avatar?: string;
};
const User = ({name, avatar}: UserProps) => (
  <UserContainer>
    <AvatarImage
      size={56}
      source={avatar && require(`@src/assets/avatars/${avatar}.png`)}
    />
    <TextContainer>
      <Title variant="headlineSmall">{name} سلام</Title>
      <DText variant="bodySmall">{translations.common.welcome}</DText>
    </TextContainer>
  </UserContainer>
);
export default User;

const UserContainer = styled.View({
  flexDirection: 'row-reverse',
  alignItems: 'center',
});

const TextContainer = styled.View({
  marginRight: spacing(2),
});

const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  textAlign: 'right',
}));

const DText = styled(Text)({
  textAlign: 'right',
  fontSize: 10,
});

const AvatarImage = styled(Avatar.Image)(({theme}) => ({
  backgroundColor: theme.colors.surface,
}));
