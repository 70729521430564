import {SafeAreaProvider as SafeArea} from 'react-native-safe-area-context';

import Auth from '@src/auth';
import QueryClientProvider from '@src/data/QueryClientProvider';
import Navigation from '@src/navigation';
import Theme from '@src/theme';

import '@src/components/organism/Sheets';

const App = () => (
  <SafeArea>
    <Theme>
      <QueryClientProvider>
        <Auth>
          <Navigation />
        </Auth>
      </QueryClientProvider>
    </Theme>
  </SafeArea>
);

export default App;
