import {View} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import BackButton from '@src/components/atoms/BackButton';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

const Header = () => (
  <Container>
    <CBackButton />
    <Title variant={'titleLarge'}>{translations.common.buyBullet}</Title>
  </Container>
);

export default Header;

const Container = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const Title = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  textAlign: 'right',
  width: '100%',
  marginTop: spacing(3),
}));
const CBackButton = styled(BackButton)({
  position: 'absolute',
  left: 0,
  top: 0,
});
