import {useState} from 'react';
import styled from '@emotion/native';
import {get} from 'lodash';

import {
  RankingType,
  useGameRankingsQuery,
  useMyGameQuery,
  useProfileQuery,
} from '@src/data/generated';
import {spacing} from '@src/theme/units';

import Header from './Header';
import SwitchView from './SwitchView';
import Table from './Table';

const Rank = () => {
  const [type, setType] = useState<RankingType>(RankingType.Weekly);

  const [{data: myGamesData}] = useMyGameQuery({
    variables: {pagination: {sort: '-createdAt'}},
  });
  const [{data: profileData, fetching}] = useProfileQuery();
  const [{data}] = useGameRankingsQuery({
    variables: {type: type as RankingType},
  });
  return (
    <Container>
      <Header
        score={profileData?.profile.totalScore}
        remainedVotes={get(
          myGamesData,
          'myGames[0].participant.remainingVotes',
          0,
        )}
      />
      <SwitchView view={type} setView={setType} />
      <Table loading={fetching} data={data?.rankings} />
    </Container>
  );
};

export default Rank;

const Container = styled.View({paddingHorizontal: spacing(2), flex: 1});
