import buttons from './buttons';
import common from './common';
import errors from './errors';
import label from './label';
import placeholders from './placeholders';
import screens from './screens';

const translations = {
  label,
  buttons,
  common,
  placeholders,
  screens,
  errors,
};

export default translations;
