import {useCallback} from 'react';
import {isSafari} from 'react-device-detect';
import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import Slider from '@react-native-community/slider';
import chroma from 'chroma-js';
import {useAtom} from 'jotai';

import VolumeIcon from '@src/assets/icons/volume-full.svg';
import {
  muteAtom,
  showVolumeAtom,
  volumeAtom,
} from '@src/components/molocules/Player/controls.store';
import {spacing} from '@src/theme/units';

const Volume = () => {
  const [volume, setVolume] = useAtom(volumeAtom);
  const [showVolume, setShowVolume] = useAtom(showVolumeAtom);
  const [mute, setMute] = useAtom(muteAtom);
  const theme = useTheme();

  const handleChangeVolume = useCallback(
    (value: number) => {
      setVolume(value);
    },
    [setVolume],
  );
  const volumeHandler = useCallback(() => {
    isSafari ? setMute(!mute) : setShowVolume(!showVolume);
  }, [mute, setMute, setShowVolume, showVolume]);

  return (
    <>
      <IconContainer onPress={volumeHandler}>
        <VolumeIcon />
      </IconContainer>
      {showVolume && (
        <VolumeContainer>
          <Slider
            minimumValue={1}
            maximumValue={100}
            step={1}
            value={volume || 0}
            onValueChange={handleChangeVolume}
            minimumTrackTintColor={theme.colors.primary}
            maximumTrackTintColor={theme.colors.onBackground}
            thumbTintColor={theme.colors.primary}
          />
        </VolumeContainer>
      )}
    </>
  );
};
export default Volume;

const IconContainer = styled.Pressable({
  marginLeft: spacing(1.5),
});

const VolumeContainer = styled.View(({theme}) => ({
  width: 150,
  height: 35,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  zIndex: 3,
  flexDirection: 'row',
  right: 0,
  bottom: 55,
  backgroundColor: chroma(theme.colors.surface).alpha(0.8) as unknown as string,
  padding: spacing(2),
  borderRadius: spacing(1.5),
}));
