import {adaptNavigationTheme, MD3LightTheme} from 'react-native-paper';
import {DefaultTheme as NavigationDefaultTheme} from '@react-navigation/native';

import {spacingUnit} from './configs';
import fonts from './fonts';
import type {Theme} from './types';
import {spacing} from './units';

export const lightTheme: Theme = {
  ...MD3LightTheme, // or MD3DarkTheme
  roundness: 1.5,
  isV3: true,
  mode: 'adaptive',
  fonts,
  spacingUnit,
  spacing,
  colors: {
    ...MD3LightTheme.colors,
    primary: '#F4D144',
    primaryContainer: '#FFEAEC',
    onPrimary: '#000000',
    secondary: '#EB5757',
    onSurface: '#767676',
    onBackground: '#FFFFFF',
    tertiary: '#DFDFDF',
    onSurfaceDisabled: '#9796A1',
    surfaceDisabled: '#EEEEEE',
    background: '#0A0A0A',
    surface: '#191919',
    outline: '#2C2C2C',
    outlineVariant: '#9AA0B4',
    warn: '#F8B200',
  },
};

export const {LightTheme: navigationLightTheme} = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme,
  materialLight: lightTheme,
});
