import {Platform} from 'react-native';
import {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {useTheme} from '@emotion/react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {get} from 'lodash';

import HomeIcon from '@src/components/atoms/HomeIcon';
import LiveIcon from '@src/components/atoms/LiveIcon';
import SettingIcon from '@src/components/atoms/SettingIcon';
import HomeScreen from '@src/components/screens/Home';
import LiveScreen from '@src/components/screens/Live';
import SettingScreen from '@src/components/screens/Setting';
import {GameStatus, useGamesQuery} from '@src/data/generated';
import useSnackbar from '@src/hooks/useSnackbar';
import {spacing, spacingPX} from '@src/theme/units';
import translations from '@src/translations';

export type BottomNavigationParamList = {
  Home: undefined;
  Club: undefined;
  Profile: undefined;
  Orders: undefined;
  BasketList: undefined;
  Setting: undefined;
  Live: {id: string};
};

const {Navigator, Screen} =
  createBottomTabNavigator<BottomNavigationParamList>();

const TabNavigation = () => {
  const theme = useTheme();
  const {updateSnackbar} = useSnackbar();
  const [{data: inProgressGame}] = useGamesQuery({
    variables: {filter: {statusIn: [GameStatus.InProgress, GameStatus.Voting]}},
  });
  if (!inProgressGame) {
    return <></>;
  }
  return (
    <Navigator
      initialRouteName="Home"
      backBehavior={'initialRoute'}
      screenOptions={{
        headerShown: false,
        tabBarItemStyle: itemStyle,
        tabBarStyle: tabBarStyle(theme),
        tabBarLabelStyle: labelStyle(theme),
        tabBarActiveTintColor: theme.colors.primary,
        tabBarInactiveTintColor: theme.colors.onSurface,
        tabBarActiveBackgroundColor: theme.colors.surface,
        tabBarInactiveBackgroundColor: theme.colors.surface,
        tabBarBadgeStyle: tabBarBadgeStyle(theme),
      }}>
      <Screen
        name="Setting"
        component={SettingScreen}
        options={{
          tabBarIcon: SettingIcon,
          title: translations.label.setting,
        }}
      />
      <Screen
        name={'Live'}
        component={LiveScreen}
        initialParams={{id: get(inProgressGame, 'games[0].id', 'nodata')}}
        listeners={{
          tabPress: e => {
            if (
              inProgressGame?.games?.length === 0 ||
              !get(inProgressGame, 'games[0].id', null)
            ) {
              updateSnackbar(
                translations.errors.error,
                translations.errors.gameNotStarted,
              );
              e.preventDefault();
            }
          },
        }}
        options={{
          // @ts-ignore
          tabBarIcon: () => (
            <LiveIcon
              isLive={
                !!inProgressGame?.games && inProgressGame?.games.length > 0
              }
            />
          ),
          title: 'Live',
          tabBarLabelStyle: {display: 'none'},
          tabBarIconStyle: clubButtonStyle,
        }}
      />
      <Screen
        name="Home"
        component={HomeScreen}
        options={{
          tabBarIcon: HomeIcon,
          title: translations.label.home,
        }}
      />
    </Navigator>
  );
};

export default TabNavigation;

const tabBarStyle = (theme: Theme) => css`
  height: 86px;
  justify-content: center;
  background: ${theme.colors.surface};
  padding-bottom: ${Platform.OS === 'web' ? '12px' : '5px'};
  padding-top: 5px;
  border-top-width: 0;
  padding-left: 50px;
  padding-right: 50px;
`;

const itemStyle = css({
  padding: spacingPX(1),
});

const labelStyle = (theme: Theme) =>
  css({
    fontFamily: theme.fonts.labelMedium?.fontFamily,
    fontSize: theme.fonts.labelMedium.fontSize,
    color: theme.colors.onSurface,
    fontWeight: '600',
  });

const tabBarBadgeStyle = (theme: Theme) => css`
  background-color: ${theme.colors.primary};
  border-radius: ${theme.roundness * 6 + 'px'};
  top: -4px;
  left: 8px;
`;
const clubButtonStyle = css({
  height: spacing(9.5),
  width: spacing(6.8),
  borderRadius: spacing(20),
  minWidth: 0,
  position: 'relative',
});
