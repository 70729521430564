import {memo} from 'react';
import {FlatList} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import type {MyGames} from '@src/data/generated';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type InnerProps = {
  height: number;
};

type Props = {
  gameVotesDetails: MyGames;
};

const ProgressCard = memo(({gameVotesDetails}: Props) => (
  <CardContainer>
    <FlatList
      snapToAlignment="start"
      nestedScrollEnabled={true}
      inverted
      showsHorizontalScrollIndicator={false}
      horizontal
      data={gameVotesDetails?.participant?.scoreReport?.rounds}
      keyExtractor={item => item.round.toLocaleString()}
      renderItem={({item}) => (
        <ColumnContainer>
          <ProgressColumn>
            <Inner
              height={(item.correct * 100) / (item.correct + item.incorrect)}
            />
          </ProgressColumn>
          <NightText variant="labelMedium">
            {translations.common.day}
            {item.round.toLocaleString('fa')}
          </NightText>
          <BulletsContainer>
            <NightSuccessBullet variant="labelMedium">
              +{item.correct.toLocaleString('fa')}
            </NightSuccessBullet>
            <NightWrongBullet variant="labelMedium">
              -{item.incorrect.toLocaleString('fa')}
            </NightWrongBullet>
          </BulletsContainer>
        </ColumnContainer>
      )}
    />
  </CardContainer>
));

export default ProgressCard;

const CardContainer = styled.View(props => ({
  backgroundColor: props.theme.colors.surface,
  borderRadius: props.theme.roundness * 8,
  maxWidth: 205,
  flex: 1,
  padding: spacing(2),
  paddingBottom: spacing(1),
  marginLeft: spacing(3),
}));

const ColumnContainer = styled.View({
  alignItems: 'center',
});

const NightText = styled(Text)(props => ({
  marginTop: spacing(2),
  color: props.theme.colors.onBackground,
}));

const ProgressColumn = styled.View(props => ({
  width: 20,
  height: 100,
  backgroundColor: props.theme.colors.secondary,
  borderRadius: props.theme.roundness * 3,
  overflow: 'hidden',
  marginHorizontal: spacing(1.5),
}));

const Inner = styled.View<InnerProps>(props => ({
  backgroundColor: props.theme.colors.primary,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: props && props.height ? props.height : 0,
}));

const BulletsContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: spacing(0.5),
});

const NightSuccessBullet = styled(Text)(props => ({
  color: props.theme.colors.primary,
}));

const NightWrongBullet = styled(Text)(props => ({
  color: props.theme.colors.secondary,
}));
