import {useNavigation} from '@react-navigation/native';

import {navigate} from '@src/navigation/RootNavigation';

export const useGoBack = () => {
  const {canGoBack, goBack} = useNavigation();

  if (canGoBack()) {
    return {goBack};
  } else {
    // handleReset();
    return {goBack: () => navigate('Main', {screen: 'Home'})};
  }
};

// export const routesMap = [
//   {
//     name: 'Main',
//     state: {
//       routes: [
//         {
//           name: 'Home',
//           path: 'Main/Home',
//         },
//         {
//           name: 'Live',
//           path: 'Main/Live',
//         },
//         {
//           name: 'Setting',
//           path: 'Main/Setting',
//         },
//       ],
//     },
//   },
// ];
