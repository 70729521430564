import {Text} from 'react-native-paper';
import styled from '@emotion/native/dist/emotion-native.cjs';

import {spacing} from '@src/theme/units';

type TimeProps = {
  timeLeft: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  };
};

const Counter = ({timeLeft}: TimeProps) => (
  <CounterContainer>
    <TimeBox>
      <TimeText variant={'titleSmall'}>روز</TimeText>
      <TimeNumber variant={'headlineLarge'}>
        {timeLeft.days.toLocaleString('fa')}
      </TimeNumber>
    </TimeBox>
    <Colon variant={'headlineLarge'}>:</Colon>
    <TimeBox>
      <TimeText variant={'titleSmall'}>ساعت</TimeText>
      <TimeNumber variant={'headlineLarge'}>
        {timeLeft.hours.toLocaleString('fa')}
      </TimeNumber>
    </TimeBox>
    <Colon variant={'headlineLarge'}>:</Colon>
    <TimeBox>
      <TimeText variant={'titleSmall'}>دقیقه</TimeText>
      <TimeNumber variant={'headlineLarge'}>
        {timeLeft.minutes.toLocaleString('fa')}
      </TimeNumber>
    </TimeBox>
    <Colon variant={'headlineLarge'}>:</Colon>
    <TimeBox>
      <TimeText variant={'titleSmall'}>ثانیه</TimeText>
      <TimeNumber variant={'headlineLarge'}>
        {timeLeft.seconds.toLocaleString('fa')}
      </TimeNumber>
    </TimeBox>
  </CounterContainer>
);

export default Counter;

const CounterContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: spacing(3),
});

const TimeBox = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  marginHorizontal: spacing(1),
  minWidth: 40,
});

const Colon = styled(Text)(props => ({
  alignSelf: 'flex-end',
  color: props.theme.colors.primary,
}));

const TimeText = styled(Text)(props => ({
  color: props.theme.colors.onSurfaceDisabled,
}));
const TimeNumber = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));
