import {memo, useCallback, useEffect} from 'react';
import {Text} from 'react-native-paper';
import type {NativeStackScreenProps} from 'react-native-screens/native-stack';
import styled from '@emotion/native';
import {useAtom, useAtomValue} from 'jotai';

import BackButton from '@src/components/atoms/BackButton';
import Container from '@src/components/atoms/Container';
import Loading from '@src/components/atoms/Loading';
import Player from '@src/components/molocules/Player';
import {
  fullscreenAtom,
  playerRefAtom,
} from '@src/components/molocules/Player/controls.store';
import {
  CurrentLiveGameAtom,
  refetchVotesAtom,
  remainedVoteAtom,
} from '@src/components/screens/Game/store';
import type {Game as GameType} from '@src/data/generated';
import {
  GameStatus,
  useMyGameQuery,
  useOnlineGameQuery,
  useUpdateGameSubscription,
} from '@src/data/generated';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';

import BottomContent from './BottomContent';
import ShootContent from './ShootContent';

type Props = NativeStackScreenProps<RootStackParamList, 'GameScreen'>;

// CustomPlayer Component
const Game = memo(({route, navigation: {navigate}}: Props) => {
  const fullscreen = useAtomValue(fullscreenAtom);
  const [playerRef] = useAtom(playerRefAtom);
  const [, setGame] = useAtom(CurrentLiveGameAtom);
  const [, setRemained] = useAtom(remainedVoteAtom);
  const [refetchVotes, setRefetchVotes] = useAtom(refetchVotesAtom);

  // Game Data
  const [{data}, refetch] = useOnlineGameQuery({
    variables: {id: route.params.gameID ?? ''},
  });

  // Initiate GameUpdate Subscription
  const [{data: gameUpdateSocketData}] = useUpdateGameSubscription({
    variables: {id: route.params.gameID ?? ''},
  });

  // Profile Data
  const [{data: myGamesData}, myGamesRefetch] = useMyGameQuery({
    variables: {
      filter: {
        statusIn: [GameStatus.InProgress, GameStatus.Voting],
        ids: [route.params.gameID ?? ''],
      },
    },
  });

  // Refetch Game Data and remained votes on GameUpdate Subscription
  useEffect(() => {
    refetch({requestPolicy: 'network-only'});
    myGamesRefetch({requestPolicy: 'network-only'});
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameUpdateSocketData]);

  // Update Game Data on GameUpdate Subscription
  useEffect(() => {
    setGame(data?.game as GameType);
  }, [data?.game, setGame]);

  // Update remained votes
  useEffect(() => {
    if (myGamesData?.myGames) {
      setRemained(myGamesData?.myGames[0]?.participant?.remainingVotes ?? 0);
    }
  }, [myGamesData?.myGames, setRemained]);

  // Refetch votes after voting
  useEffect(() => {
    refetchVotes && myGamesRefetch({requestPolicy: 'network-only'});
    setRefetchVotes(false);
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchVotes]);

  // update Live time function
  const handleGoToLive = useCallback(() => {
    if (
      playerRef?.current?.getDuration() &&
      (playerRef?.current?.getDuration() || 60) -
        (playerRef?.current?.getCurrentTime() || 0) >
        60
    ) {
      playerRef?.current?.seekTo(playerRef?.current?.getDuration());
    }
  }, [playerRef]);

  // navigate to played game screen as show ends
  useEffect(() => {
    if (data?.game.status === GameStatus.Finished) {
      navigate('PlayedEpisode', {id: route.params.gameID});
    }
  }, [data?.game.status, navigate, route.params.gameID]);

  // Update Live time event update every 1min
  useEffect(() => {
    const interval = setInterval(() => {
      handleGoToLive();
    }, 10000);
    return () => clearInterval(interval);
  }, [handleGoToLive]);

  // Show loading On mounting
  if (!data?.game) {
    return <Loading />;
  }
  if (!data?.game.streamHlsURL) {
    return <></>;
  }
  return (
    <Container disablePadding>
      <Player
        url={data?.game.streamHlsURL}
        thumbnail={
          data?.game.thumbnail ??
          'https://static.cdn.asset.aparat.com/avt/49357784-4147-b__3410.jpg'
        }
        isLive={
          data.game.status === GameStatus.InProgress ||
          data.game.status === GameStatus.Voting
        }
        children={
          <>
            <PlayerHeader game={data.game as GameType} />
            <BottomContent game={data.game as GameType} />
          </>
        }
      />
      {!fullscreen && <ShootContent />}
    </Container>
  );
});

export default Game;

type HeaderProps = {
  game: GameType;
};

const PlayerHeader = ({game}: HeaderProps) => (
  <RowContainer>
    <CustomBackButton />
    <Title variant="titleMedium">{`${game.title} - قسمت ${
      game?.episode ?? '۲'
    }`}</Title>
  </RowContainer>
);

const RowContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  zIndex: 2,
  top: 0,
  left: 0,
  right: 0,
  padding: spacing(1),
});
const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
}));
const CustomBackButton = styled(BackButton)({
  position: 'relative',
  top: 0,
  left: 0,
  margin: 0,
  padding: 0,
  width: 40,
  height: 40,
  // eslint-disable-next-line max-lines
});
