import {useCallback} from 'react';
import {Pressable, View} from 'react-native';
import {Divider, Text} from 'react-native-paper';
import styled from '@emotion/native';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';

import ArrowIcon from '@src/assets/icons/leftarrow.svg';
import LockIcon from '@src/assets/icons/lock.svg';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import Profile from './Profile';

type TermsRouteProp = StackNavigationProp<RootStackParamList, 'Terms'>;

const Main = () => {
  const {navigate} = useNavigation<TermsRouteProp>();

  const onPress = useCallback(() => {
    navigate('Terms');
  }, [navigate]);

  return (
    <>
      <Container>
        <Profile />
        <CustomDivider />
        <ItemContainer onPress={onPress}>
          <ItemComponent>
            <ItemText variant={'titleMedium'}>
              {translations.common.termsAndConditions}
            </ItemText>
            <LockIcon />
          </ItemComponent>
          <ArrowIcon />
        </ItemContainer>
        <CustomDivider />
      </Container>
    </>
  );
};

export default Main;

const Container = styled.View({
  flex: 1,
  width: '100%',
});

const CustomDivider = styled(Divider)(props => ({
  width: '100%',
  backgroundColor: props.theme.colors.outline,
}));

const ItemContainer = styled(Pressable)({
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginVertical: spacing(3.5),
});

const ItemComponent = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
});

const ItemText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginRight: spacing(2.5),
}));
