import type {MutableRefObject} from 'react';
import type {View} from 'react-native';
import type ReactPlayer from 'react-player';
import {atom} from 'jotai';

export const isPlayingAtom = atom<boolean>(false);
export const showVolumeAtom = atom<boolean>(false);
export const fullscreenAtom = atom<boolean>(false);
export const volumeAtom = atom<number>(100); // 0-100
export const showControlsAtom = atom<boolean>(true); // 0-100
export const bufferingAtom = atom<boolean>(true);
export const muteAtom = atom<boolean>(false);
export const progressAtom = atom<number>(0); // 0-100
export const durationAtom = atom<number>(0); // 0-100
export const playerRefAtom = atom<MutableRefObject<ReactPlayer | null> | null>(
  null,
); // 0-100
export const containerRefAtom = atom<MutableRefObject<View | null> | null>(
  null,
); // 0-100
