import styled from '@emotion/native';

import type {Game, MyGames, UserGameScoreQuery} from '@src/data/generated';
import {spacing} from '@src/theme/units';

import EntityCard from './EntityCard';
import Information from './Information';
import NightWatch from './NightWatch';
import Players from './Players';
import ProgressCard from './ProgressCard';

type Props = {
  game?: Game;
  userDetails: UserGameScoreQuery;
  gameVotesDetails: MyGames;
};

const Main = ({game, userDetails, gameVotesDetails}: Props) => (
  <MainContainer>
    <Information game={game} />
    <InfoCard>
      <EntityCard
        userDetails={userDetails}
        gameVotesDetails={gameVotesDetails}
      />
      <ProgressCard gameVotesDetails={gameVotesDetails} />
    </InfoCard>
    <NightWatch game={game} />
    <Players game={game} />
  </MainContainer>
);

export default Main;

const MainContainer = styled.View({
  paddingHorizontal: spacing(3),
  paddingBottom: spacing(3),
});

const InfoCard = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: spacing(3),
});
