import {View} from 'react-native';
import {Divider, Text} from 'react-native-paper';
import styled from '@emotion/native/dist/emotion-native.cjs';
import {digitsEnToFa} from '@persian-tools/persian-tools';

import BulletIcon from '@src/assets/icons/bullet.svg';
import CupIcon from '@src/assets/icons/trophy.svg';
import type {MyGames, UserGameScoreQuery} from '@src/data/generated';
import {spacing} from '@src/theme/units';

type Props = {
  userDetails: UserGameScoreQuery;
  gameVotesDetails: MyGames;
};

const EntityCard = ({userDetails, gameVotesDetails}: Props) => (
  <CardContainer>
    <View>
      <RateText>امتیاز شما در این بازی</RateText>
      <RateNumContainer>
        <CupIcon />
        <RateNumber variant="headlineMedium">
          {digitsEnToFa(gameVotesDetails.participant.score ?? 0)}
        </RateNumber>
      </RateNumContainer>
    </View>
    <CustomDivider />
    <BulletText variant="labelSmall">تعداد تیر باقی مانده</BulletText>
    <BulletContainer>
      <BulletIcon />
      <BulletNumber variant="headlineMedium">
        {digitsEnToFa(userDetails?.profile?.remainingVotes ?? 0)}
      </BulletNumber>
    </BulletContainer>
  </CardContainer>
);

export default EntityCard;

const CardContainer = styled.View(props => ({
  backgroundColor: props.theme.colors.surface,
  maxWidth: '105',
  borderRadius: props.theme.roundness * 8,
  alignItems: 'center',
  padding: spacing(1),
  paddingHorizontal: spacing(2),
  justifyContent: 'space-around',
}));

const CustomDivider = styled(Divider)(props => ({
  width: '95%',
  backgroundColor: props.theme.colors.onSurface,
}));

const RateText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginBottom: spacing(1),
  textAlign: 'center',
  fontSize: 10,
}));

const RateNumContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const RateNumber = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginTop: spacing(1),
  marginLeft: spacing(0.5),
}));

const BulletContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '90%',
});

const BulletText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  textAlign: 'center',
  fontSize: 10,
}));

const BulletNumber = styled(Text)(props => ({
  textAlign: 'center',
  alignItems: 'center',
  color: props.theme.colors.onBackground,
  marginTop: spacing(0.4),
}));
