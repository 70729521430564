import {useCallback} from 'react';
import {View} from 'react-native';
import styled, {css} from '@emotion/native';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import {useUpdateAtom} from 'jotai/utils';

import CloseIcon from '@src/assets/icons/close-icon.svg';
import Container from '@src/components/atoms/Container';
import IconButton from '@src/components/atoms/IconButton';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';

import MafiaLogo from './assets/mafiaLogo.svg';
import {dataMock} from './data.mock';
import Pagination from './Pagination';
import {IntroCompletedAtom} from './store';

type mainScreenProp = StackNavigationProp<RootStackParamList, 'Welcome'>;

const Introduction = () => {
  const setCompleted = useUpdateAtom(IntroCompletedAtom);
  const {navigate} = useNavigation<mainScreenProp>();

  const handleCloseButton = useCallback(() => {
    setCompleted(true);
    navigate('Main');
  }, [navigate, setCompleted]);

  return (
    <Container style={containerStyle}>
      <HeaderContainer>
        <CustomCloseBtn
          onPress={handleCloseButton}
          mode="outlined"
          icon={CloseIcon}
        />
        <LogoContainer>
          <MafiaLogo />
        </LogoContainer>
        <Spacer />
      </HeaderContainer>
      <Pagination data={dataMock} />
    </Container>
  );
};

export default Introduction;

const containerStyle = css({
  alignItems: 'stretch',
  justifyContent: 'center',
});

const CustomCloseBtn = styled(IconButton)(props => ({
  borderRadius: props.theme.roundness * 40,
  width: 56,
  height: 56,
  margin: 0,
}));

const HeaderContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
});
const LogoContainer = styled(View)({
  alignItems: 'center',
  justifyContent: 'center',
  bottom: spacing(0.5),
});

const Spacer = styled(View)({
  width: 56,
  height: 56,
});
