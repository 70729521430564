import {Text} from 'react-native-paper';
import styled from '@emotion/native/dist/emotion-native.cjs';

import type {Game} from '@src/data/generated';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import VideoPlayerWeb from './VideoPlayer.web';

type Props = {
  game?: Game;
};
const NightWatch = ({game}: Props) => {
  if (game?.archiveHlsURL?.length === 0) {
    return <></>;
  }
  return (
    <Container>
      <NightWatchText variant="bodyLarge">
        {translations.common.nightWatch}
      </NightWatchText>
      <VideoPlayerWeb
        videoURL={game?.archiveHlsURL}
        thumbnailURL={game?.thumbnail}
      />
    </Container>
  );
};

export default NightWatch;

const Container = styled.View({
  alignItems: 'flex-end',
  marginTop: spacing(3),
});

const NightWatchText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginVertical: spacing(3),
}));
