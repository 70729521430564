import type {Dispatch} from 'react';
import {memo} from 'react';
import styled from '@emotion/native';

import ButtonGroup from '@src/components/atoms/ButtonGroup';
import {RankingType} from '@src/data/generated';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type Props = {
  setView: Dispatch<RankingType>;
  view: string;
};

const SwitchView = ({setView, view}: Props) => (
  <Container>
    <ButtonGroup
      value={view}
      onValueChange={setView as Dispatch<string>}
      buttons={[
        {
          value: RankingType.LastGame,
          label: translations.label.lastGame,
        },
        {
          value: RankingType.Weekly,
          label: translations.label.weaklyGame,
        },
        {
          value: RankingType.Monthly,
          label: translations.label.monthlyGame,
        },
      ]}
    />
  </Container>
);

export default memo(SwitchView);

const Container = styled.View({
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: spacing(2),
});
