import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import PlayerCard from '@src/components/screens/PlayedEpisode/Main/Players/PlayerCard';
import type {Game} from '@src/data/generated';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type Props = {
  game?: Game;
};

const Players = ({game}: Props) => (
  <Container>
    <PlayerText variant="titleMedium">{translations.common.players}</PlayerText>
    <CardsContainer>
      {game?.players?.map(
        player =>
          player?.name && <PlayerCard key={player.name} player={player} />,
      )}
    </CardsContainer>
  </Container>
);

export default Players;

const Container = styled.View({
  marginTop: spacing(3),
  alignItems: 'flex-end',
});

const PlayerText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));

const CardsContainer = styled.View({
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
});
