type PersianNumbers = {
  [key: number]: string;
};

const ones: PersianNumbers = {
  0: 'م',
  1: 'یکم',
  2: 'دوم',
  3: 'سوم',
  4: 'چهارم',
  5: 'پنجم',
  6: 'ششم',
  7: 'هفتم',
  8: 'هشتم',
  9: 'نهم',
};

const tens: PersianNumbers = {
  10: 'ده',
  20: 'بیست',
  30: 'سی',
  40: 'چهل',
  50: 'پنجاه',
  60: 'شصت',
  70: 'هفتاد',
  80: 'هشتاد',
  90: 'نود',
};

const bigNumbers = {
  100: 'صد',
  1000: 'هزار',
  1000000: 'میلیون',
};

export const translateNumbers = (num: number): string => {
  let output = '';

  if (num < 10) {
    output += ones[num];
  } else if (num < 100) {
    output += tens[Math.floor(num / 10) * 10];
    if (num % 10 > 0) {
      output += ' و ' + ones[num % 10];
    }
  } else if (num < 1000) {
    output += ones[Math.floor(num / 100)] + ' ' + bigNumbers[100];
    if (num % 100 > 0) {
      output += ' و ' + translateNumbers(num % 100);
    }
  } else if (num < 1000000) {
    output += ones[Math.floor(num / 1000)] + ' ' + bigNumbers[1000];
    if (num % 1000 > 0) {
      output += ' و ' + translateNumbers(num % 1000);
    }
  } else if (num < 1000000000) {
    output +=
      translateNumbers(Math.floor(num / 1000000)) + ' ' + bigNumbers[1000000];
    if (num % 1000000 > 0) {
      output += ' و ' + translateNumbers(num % 1000000);
    }
  }

  return output;
};
