import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import {spacing} from '@src/theme/units';

type MafiaCardProps = {
  isDead: boolean;
  name: string;
  avatar: string;
  onCardPress: () => void;
  selected?: boolean;
};

const MafiaCard = ({
  onCardPress,
  name,
  avatar,
  isDead,
  selected,
}: MafiaCardProps) => (
  <Card onPress={isDead ? undefined : onCardPress}>
    <Avatar
      source={{uri: avatar}}
      blurRadius={isDead ? 1 : 0}
      selected={selected}
    />
    {!isDead && (
      <Bullet source={require('@src/assets/images/playerBullet.png')} />
    )}
    <Name isDead={isDead} variant="titleSmall">
      {name}
    </Name>
    {isDead && <Dead source={require('@src/assets/images/deadPlayer.png')} />}
  </Card>
);
export default MafiaCard;

type Type = {
  isDead: boolean;
};
type ImageType = {
  selected?: boolean;
};

const Card = styled.Pressable({
  position: 'relative',
  margin: spacing(1.5),
  flexDirection: 'column',
});
const Avatar = styled.Image<ImageType>(props => ({
  width: 70,
  height: 70,
  borderRadius: spacing(2),
  overflow: 'hidden',
  outlineWidth: props.selected ? 2 : 0,
  outlineColor: props.theme.colors.primary,
  outlineStyle: 'solid',
}));
const Bullet = styled.Image({
  width: 40,
  height: 40,
  position: 'absolute',
  top: spacing(5),
  left: -spacing(1.5),
});
const Dead = styled.Image({
  width: 70,
  height: 70,
  position: 'absolute',
  top: 0,
  left: 0,
  opacity: 1,
  borderRadius: spacing(2),
  overflow: 'hidden',
  backgroundColor: 'rgba(0,0,0,0.5)',
});
const Name = styled(Text)<Type>(({theme, isDead}) => ({
  color: isDead ? theme.colors.onSurface : theme.colors.onBackground,
  textAlign: 'center',
  width: 70,
  marginTop: spacing(1),
  overflow: 'hidden',
}));
