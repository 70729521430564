import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';

import LogoutIcon from '@src/assets/icons/logout.svg';
import Container from '@src/components/atoms/Container';
import useLogout from '@src/hooks/useLogout';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import {version} from '../../../../package.json';

import Header from './Header';
import Profile from './Main';

const Setting = () => {
  const logout = useLogout();

  return (
    <Container>
      <Header />
      <Profile />
      <FooterContainer>
        <Version variant="titleMedium">{`${
          translations.common.version
        } ${digitsEnToFa(version)}`}</Version>
        <LogOutContainer onPress={logout}>
          <LogOutText>{translations.common.logOutAccount}</LogOutText>
          <LogoutIcon />
        </LogOutContainer>
      </FooterContainer>
    </Container>
  );
};

export default Setting;

const FooterContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

const LogOutContainer = styled.Pressable(props => ({
  flexDirection: 'row',
  justifyContent: 'center',
  backgroundColor: props.theme.colors.surface,
  borderRadius: props.theme.roundness * 13.5,
  padding: spacing(1),
  paddingLeft: spacing(3.5),
  paddingRight: spacing(1.5),

  alignItems: 'center',
}));

const LogOutText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginRight: spacing(1),
}));

const Version = styled(Text)(props => ({
  color: props.theme.colors.onSurface,
}));
