import {memo} from 'react';
import {SheetManager} from 'react-native-actions-sheet';
import {Button, Text} from 'react-native-paper';
import styled from '@emotion/native';
import {useAtom, useAtomValue} from 'jotai';

import BulletsCard from '@src/components/atoms/BulletsCard';
import {fullscreenAtom} from '@src/components/molocules/Player/controls.store';
import SheetsID from '@src/components/organism/Sheets/SheetsID';
import {remainedVoteAtom} from '@src/components/screens/Game/store';
import type {Game} from '@src/data/generated';
import {translateNumbers} from '@src/generators/translateNumbers';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type Props = {
  game: Game;
};

const BottomContent = ({game}: Props) => {
  const [isFullscreen, setFullscreen] = useAtom(fullscreenAtom);
  const remainingBullets = useAtomValue(remainedVoteAtom);

  const handleShootModal = async () => {
    setFullscreen(false);
    if (document) {
      await document.exitFullscreen();
      await SheetManager.show(SheetsID.mafiaSelectionSheet, {payload: {game}});
    }
  };

  if (!game.currentRound) {
    return <></>;
  }
  return (
    <Container>
      <BulletsContainer>
        <BulletsCard bullets={remainingBullets} />
        {isFullscreen && game.status === 'voting' && (
          <CButton mode={'outlined'} onPress={handleShootModal}>
            همین حالا مافیا رو بزن!
          </CButton>
        )}
      </BulletsContainer>
      <Title variant="titleSmall">
        {`${translations.common.day} ${translateNumbers(game.currentRound)}
          `}
      </Title>
    </Container>
  );
};

export default memo(BottomContent);

const Container = styled.View({
  position: 'absolute',
  bottom: spacing(5),
  left: 0,
  right: 0,
  width: '90%',
  marginHorizontal: 'auto',
  zIndex: 3,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: spacing(3),
});
const BulletsContainer = styled.View({
  flexDirection: 'row',
});
const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  height: 20,
}));

const CButton = styled(Button)(({theme}) => ({
  borderColor: theme.colors.primary,
}));
