/* eslint-disable max-lines */
import {memo, useCallback} from 'react';
import {Image} from 'react-native';
import {Divider, Text} from 'react-native-paper';
import styled from '@emotion/native';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import chroma from 'chroma-js';
import {get} from 'lodash';

import EyeIcon from '@src/assets/icons/eye.svg';
import CupIcon from '@src/assets/icons/goldenCupSmall.svg';
import type {Game} from '@src/data/generated';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import MafiaCard from './MafiaCard';

type ScreenProp = StackNavigationProp<RootStackParamList, 'Main'>;
// TODO: fix type

type Props = {
  data: Game;
  score?: number;
};

const PreviousGameCard = memo(({data, score}: Props) => {
  const {navigate} = useNavigation<ScreenProp>();

  const handleRedirect = useCallback(() => {
    navigate('PlayedEpisode', {id: get(data, 'id', '')});
  }, [data, navigate]);

  if (!data.players?.length) {
    return <></>;
  }
  return (
    <Card onPress={handleRedirect}>
      <CImage source={{uri: data.banner ?? ''}} />
      <Chip>
        <EyeIcon />
        <ChipText variant="labelMedium">
          {`${Number(data.participantsCount).toLocaleString('fa')}`}
        </ChipText>
        <ChipText variant="labelMedium"> {translations.common.person}</ChipText>
      </Chip>

      <Content>
        <Title variant="titleMedium">
          {data.title}-{translations.common.episode}{' '}
          {Number(data.episode).toLocaleString('fa')}
        </Title>
        <CitizenArea>
          {/*TODO fix type*/}
          {data?.players
            ?.filter(player => player?.role?.type === 'mafia')
            ?.map(player => (
              <MafiaCard
                name={player.name}
                avatar={player.avatar}
                key={player.name}
              />
            ))}
        </CitizenArea>

        <TextArea>
          <MafiaText variant="labelMedium">
            :{translations.common.citizens}
          </MafiaText>
          <MafiaListText variant="labelMedium">
            {data?.players
              ?.filter(p => p?.role?.type === 'citizen')
              .map(p => p.name)
              .join(', ')}
          </MafiaListText>
        </TextArea>

        <CDivider />
        <ScoreArea>
          <ScoreText variant="labelLarge">
            {translations.common.yourScoreFromGame}
          </ScoreText>
          <ScoreChip>
            <ScoreChipText variant="headlineSmall">
              {`${Number(score ?? 0).toLocaleString('fa')}`}
            </ScoreChipText>
            <CupIcon />
          </ScoreChip>
        </ScoreArea>
      </Content>
    </Card>
  );
});

export default PreviousGameCard;

const Card = styled.Pressable(({theme}) => ({
  width: 295,
  borderRadius: spacing(1.5),
  overflow: 'hidden',
  marginTop: spacing(2),
  backgroundColor: theme.colors.surface,
  position: 'relative',
  marginLeft: spacing(3),
}));

const Content = styled.View({
  padding: spacing(1),
  flex: 1,
});

const TextArea = styled.View({
  flexDirection: 'row-reverse',
  flexWrap: 'wrap',
  marginTop: spacing(2),
  flex: 1,
});

const CImage = styled(Image)({width: '100%', height: 150});

const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  textAlign: 'right',
  fontWeight: 'bold',
}));

const MafiaText = styled(Text)(({theme}) => ({
  color: theme.colors.primary,
  textAlign: 'right',
  fontWeight: '300',
}));

const MafiaListText = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  textAlign: 'right',
  fontWeight: '300',
}));

const Chip = styled.View(({theme}) => ({
  backgroundColor: chroma(theme.colors.surface).alpha(0.8) as unknown as string,
  position: 'absolute',
  top: spacing(1.5),
  left: spacing(1.5),
  flexDirection: 'row-reverse',
  padding: spacing(1),
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
}));

const ScoreChip = styled.View(({theme}) => ({
  backgroundColor: chroma(theme.colors.primary).darken(
    2.5,
  ) as unknown as string,
  flexDirection: 'row-reverse',
  padding: spacing(1),
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
}));

const ChipText = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
}));
const ScoreChipText = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  marginLeft: spacing(1),
  fontWeight: '500',
  textAlign: 'center',
}));

const CitizenArea = styled.View({
  flexDirection: 'row-reverse',
  marginTop: spacing(2),
});

const CDivider = styled(Divider)(props => ({
  width: '100%',
  marginVertical: spacing(2),
  backgroundColor: props.theme.colors.outline,
}));

const ScoreArea = styled.View({
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ScoreText = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
}));
