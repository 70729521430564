import {atomWithStorage} from 'jotai/utils';

import type {Game} from '@src/data/generated';

export const CurrentLiveGameAtom = atomWithStorage<Game | null>(
  'CurrentLiveGameAtom',
  null,
);
export const remainedVoteAtom = atomWithStorage<number>('RemainedVoteAtom', 0);
export const refetchVotesAtom = atomWithStorage<boolean>(
  'refetchVotesAtom',
  false,
);
