import {Image} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native/dist/emotion-native.cjs';

import type {Player} from '@src/data/generated';
import {spacing} from '@src/theme/units';

type Props = {
  player: Player;
};

const PlayerCard = ({player}: Props) => (
  <PlayerCardContainer>
    <TextArea>
      <PlayerName variant="labelMedium">{player.name}</PlayerName>
      <PlayerDetail variant="labelMedium">{player?.role?.name}</PlayerDetail>
    </TextArea>
    <PlayerImage
      source={
        player.avatar
          ? {uri: player.avatar}
          : require('../../../mock/chracter.jpg')
      }
      resizeMode="cover"
      resizeMethod="auto"
    />
  </PlayerCardContainer>
);

export default PlayerCard;

const PlayerCardContainer = styled.View({
  flexDirection: 'row',
  marginTop: spacing(3),
  width: 150,
  alignItems: 'center',
  justifyContent: 'flex-end',
});
const TextArea = styled.View({
  flex: 1,
  marginRight: spacing(1),
});

const PlayerImage = styled(Image)(props => ({
  width: 60,
  height: 60,
  borderRadius: props.theme.roundness * 10,
}));

const PlayerName = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  textAlign: 'right',
}));
const PlayerDetail = styled(Text)(props => ({
  color: props.theme.colors.primary,
  flex: 1,
  textAlign: 'right',
  marginTop: spacing(0.5),
}));
