import {View} from 'react-native';
import {Divider, Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';

import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type UserScoresProps = {
  totalScore?: number;
  monthlyScore?: number;
};
const UserScores = ({totalScore, monthlyScore}: UserScoresProps) => (
  <Card>
    <CTitle>{translations.common.lastGame}</CTitle>
    <CText variant="titleLarge">{digitsEnToFa(totalScore || '0')}</CText>
    <CDivider />
    <CTitle>{translations.common.lastWeek}</CTitle>
    <CText variant="titleLarge">{digitsEnToFa(monthlyScore || '0')}</CText>
  </Card>
);

export default UserScores;

const Card = styled(View)(({theme}) => ({
  backgroundColor: theme.colors.surface,
  borderRadius: 12,
  overflow: 'hidden',
  height: 150,
  minWidth: 90,
  padding: spacing(1.5),
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: spacing(3),
}));

const CDivider = styled(Divider)(({theme}) => ({
  width: '100%',
  marginTop: spacing(1.5),
  marginBottom: spacing(1.5),
  backgroundColor: theme.colors.onSurface,
}));

const CText = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
}));

const CTitle = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  fontSize: 10,
  marginBottom: spacing(0.5),
}));
