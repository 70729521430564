import * as React from 'react';
import {memo} from 'react';
import {StatusBar as SB} from 'react-native';
import {useTheme} from '@emotion/react';

const StatusBar = () => {
  const theme = useTheme();
  return (
    <SB
      barStyle={theme.dark ? 'light-content' : 'dark-content'}
      backgroundColor={theme.colors.background}
    />
  );
};

export default memo(StatusBar);
