const placeholders = {
  search: 'جست و جو',
  newNumber: 'شماره تلفن جدید',
  description: '... توضیحات سفارش خود را بنویسید',
  addressDetails: 'جزییات پلاک و واحد',
  addressName: 'عنوان آدرس مثل خانه ',
  addressPhone: 'شماره تماس (اختیاری)',
  searchArea: 'جستجو محله ...',
  name: 'نام',
  myself: 'خودم',
  simpleNumber: '۰۹۱۲۳۴۵۶۷۸۹',
};

export default placeholders;
