import {useCallback, useEffect, useMemo, useState} from 'react';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';
import {padStart} from 'lodash';

const TimeCounter = () => {
  const [time, setTime] = useState(120);

  const minutes = digitsEnToFa(
    padStart(Math.floor(time / 60).toString(), 2, '0'),
  );
  const seconds = digitsEnToFa(
    padStart(Math.floor(time % 60).toString(), 2, '0'),
  );

  const countdown = useCallback(() => {
    if (time <= 0) {
      clearTimeout(timerId);
    } else {
      setTime(e => e - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timerId = useMemo(() => setInterval(countdown, 1000), [time]);

  useEffect(
    () => () => {
      clearTimeout(timerId);
    },
    [timerId],
  );

  return (
    <TCounter variant="displaySmall">
      {minutes}:{seconds}
    </TCounter>
  );
};

export default TimeCounter;

const TCounter = styled(Text)(({theme}) => ({
  textAlign: 'center',
  width: 100,
  marginHorizontal: 'auto',
  color: theme.colors.onBackground,
}));
