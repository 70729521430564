import {memo} from 'react';
import {Image} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';

import {spacing} from '@src/theme/units';
import translations from '@src/translations';
// TODO fix type
const PreviousGameCard = memo(({data}: any) => (
  <Card>
    <CImage source={{uri: data.item.banner}} />
    <Content>
      <Title variant="titleMedium">
        {data.item.title} - {translations.common.episode}
        {digitsEnToFa(data.item.episode)}
      </Title>
      <Description variant="titleSmall">{data.item.subtitle}</Description>
    </Content>
  </Card>
));

export default PreviousGameCard;

const Card = styled.Pressable(({theme}) => ({
  width: 270,
  borderRadius: spacing(1.5),
  overflow: 'hidden',
  marginTop: spacing(2),
  backgroundColor: theme.colors.surface,
  position: 'relative',
  marginLeft: spacing(3),
}));

const Content = styled.View({
  padding: spacing(2),
});

const CImage = styled(Image)({width: '100%', height: 150});

const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  textAlign: 'right',
  fontWeight: 'bold',
}));

const Description = styled(Text)(({theme}) => ({
  color: theme.colors.onSurface,
  textAlign: 'right',
  fontWeight: '300',
}));
