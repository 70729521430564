import {useUpdateAtom} from 'jotai/utils';

import {snackbarAtom} from '@src/components/providers/SnackbarProvider';

type SnackbarApi = {
  updateSnackbar: (
    title: string,
    message: string,
    onDismiss?: () => void,
  ) => void;
};

const useSnackbar = (): SnackbarApi => {
  const setSnackbar = useUpdateAtom(snackbarAtom);

  const updateSnackbar = (
    title?: string,
    message?: string,
    onDismiss?: () => void,
  ) => {
    setSnackbar({
      title,
      message,
      onDismiss,
    });
  };

  return {
    updateSnackbar,
  };
};

export default useSnackbar;
