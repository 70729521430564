import {Image} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import type {Player} from '@src/data/generated';
import {spacing} from '@src/theme/units';

type Props = {
  data: Player;
};

const Players = ({data}: Props) => (
  <PlayersContainer>
    <PlayerCard>
      <PlayerName>{data.name}</PlayerName>
      <ImageComponent>
        <PlayerImage
          source={
            data.avatar
              ? {uri: data.avatar}
              : require('../../mock/mafiachrac.jpg')
          }
        />
        <RoleGuessCard source={require('./assets/GuessCard.png')} />
      </ImageComponent>
    </PlayerCard>
  </PlayersContainer>
);

export default Players;

const PlayersContainer = styled.View({marginTop: spacing(3), width: 150});

const ImageComponent = styled.View({});

const PlayerImage = styled(Image)(props => ({
  width: 60,
  height: 60,
  borderRadius: props.theme.roundness * 10,
}));

const RoleGuessCard = styled(Image)({
  position: 'absolute',
  width: spacing(4),
  height: spacing(4),
  top: '60%',
  right: '70%',
});

const PlayerCard = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
});

const PlayerName = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginRight: spacing(2),
  flex: 1,
  textAlign: 'right',
}));
