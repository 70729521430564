import {Image} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {get} from 'lodash';

import EyeIcon from '@src/assets/icons/eye.svg';
import BackButton from '@src/components/atoms/BackButton';
import type {Game} from '@src/data/generated';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type Props = {
  game?: Game;
};

const LiveScreen = ({game}: Props) => (
  <>
    <HeaderImage
      source={
        game?.banner ? {uri: game?.banner} : require('../mock/headerImage.jpg')
      }
      resizeMethod={'auto'}
      resizeMode={'cover'}
    />
    <BackButton />
    <LiveInfoContainer>
      {game?.status === ('in_progress' || 'voting') && (
        <LiveLabelBackground>
          <LiveTextComponent>
            <LiveText> {translations.common.live}</LiveText>
          </LiveTextComponent>
        </LiveLabelBackground>
      )}
      <OnlineEntity>
        <EntityText>{translations.common.person}</EntityText>
        <EntityNumber>{get(game, 'participantsCount', 0)}</EntityNumber>
        <EyeIcon />
      </OnlineEntity>
    </LiveInfoContainer>
  </>
);

export default LiveScreen;

const HeaderImage = styled(Image)({
  width: '100%',
  height: 300,
});

const LiveInfoContainer = styled.View({
  position: 'absolute',
  flexDirection: 'row',
  justifyContent: 'space-between',
  top: 240,
  alignItems: 'center',
  marginLeft: spacing(3),
});

const OnlineEntity = styled.View(props => ({
  flexDirection: 'row',
  backgroundColor: 'rgba(255,255,255,0.29)',
  paddingVertical: spacing(1),
  paddingHorizontal: spacing(1.5),
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: props.theme.roundness * 10,
  marginLeft: spacing(1),
}));

const EntityNumber = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginRight: spacing(0.2),
}));
const EntityText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  marginRight: spacing(0.2),
}));

const LiveLabelBackground = styled.View(props => ({
  backgroundColor: props.theme.colors.secondary,
  borderRadius: props.theme.roundness * 10,
  height: spacing(5),
  justifyContent: 'center',
  alignItems: 'center',
  width: 60,
}));

const LiveTextComponent = styled.View();

const LiveText = styled(Text)({
  color: '#ffff',
  textAlign: 'center',
  paddingVertical: spacing(0.4),
});
