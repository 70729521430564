import {Image} from 'react-native';
import styled from '@emotion/native';

import image from '@src/assets/images/pwa.png';
import {spacing} from '@src/theme/units';

const PWAScreen = () => <CImage resizeMode={'contain'} source={{uri: image}} />;

export default PWAScreen;

const CImage = styled(Image)({
  width: '90%',
  height: 500,
  margin: 'auto',
  paddingHorizontal: spacing(4),
});
