import {useCallback} from 'react';
import styled from '@emotion/native';
import {useAtom} from 'jotai';

import PauseIcon from '@src/assets/icons/pause.svg';
import PlayControllerIcon from '@src/assets/icons/player-play.svg';
import {isPlayingAtom} from '@src/components/molocules/Player/controls.store';
import {spacing} from '@src/theme/units';

const PlayControl = () => {
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom);

  const togglePlay = useCallback(() => {
    setIsPlaying(!isPlaying);
  }, [isPlaying, setIsPlaying]);
  return (
    <IconContainer onPress={togglePlay}>
      {isPlaying ? <PauseIcon /> : <PlayControllerIcon />}
    </IconContainer>
  );
};
export default PlayControl;

const IconContainer = styled.Pressable({
  marginLeft: spacing(1.5),
});
