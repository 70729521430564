import {memo} from 'react';
import type {IconButtonProps} from 'react-native-paper';
import {IconButton as IB} from 'react-native-paper';
import {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {useTheme} from '@emotion/react';

const IconButton = ({style, ...otherProps}: IconButtonProps) => {
  const theme = useTheme();
  return (
    <IB
      style={[buttonStyle(theme), style]}
      rippleColor={'transparent'}
      {...otherProps}
    />
  );
};

export default memo(IconButton);

const buttonStyle = (theme: Theme) =>
  css({
    borderRadius: theme.roundness * 5,
    backgroundColor: 'transparent',
  });
