/* eslint-disable */

import {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {createStackNavigator} from '@react-navigation/stack';
import {useAtomValue} from 'jotai';

import {otpAtom, refreshTokenAtom} from '@src/auth/store';
import OTPScreen from '@src/components/screens/OTP';
import WelcomeScreen from '@src/components/screens/Welcome';
import translations from '@src/translations';
import LiveScreen from '@src/components/screens/Live';
import TabNavigation from '@src/navigation/TabNavigation';
import SignUpScreen from '@src/components/screens/SignUp';
import PlayedEpisodeScreen from '@src/components/screens/PlayedEpisode';
import GameScreen from '@src/components/screens/Game';
import RankScreen from '@src/components/screens/Rank';
import Introduction from '@src/components/screens/Introduction';
import TermsScreen from '@src/components/screens/Terms';
import BasketScreen from '@src/components/screens/Basket';
import usePWA from 'react-pwa-install-prompt';
import {isIOS} from 'react-device-detect';
import PWAScreen from '@src/components/screens/PWA';

export type RootStackParamList = {
  Welcome: undefined;
  Login: undefined;
  OTP: undefined;
  Live: {id: string};
  Main: undefined;
  SignUp: undefined;
  PlayedEpisode: {id: string};
  GameScreen: {
    gameID: string;
  };
  Rank: undefined;
  Introduction: undefined;
  Terms: undefined;
  Basket: undefined;
  PWA: undefined;
};

const Stack = createStackNavigator<RootStackParamList>();

const RootStack = () => {
  const refreshToken = useAtomValue(refreshTokenAtom);
  const otp = useAtomValue(otpAtom);
  const {isStandalone, isInstallPromptSupported} = usePWA();

  return (
    <Stack.Navigator>
      {isIOS && !isStandalone ? (
        <Stack.Screen
          name="PWA"
          component={PWAScreen}
          options={{
            headerShown: false,
          }}
        />
      ) : (
        <>
          {refreshToken ? (
            <Stack.Group screenOptions={{headerShown: false}}>
              <Stack.Screen name="Main" component={TabNavigation} />
              <Stack.Screen name="Introduction" component={Introduction} />
              <Stack.Screen name="Live" component={LiveScreen} />
              <Stack.Screen
                name="PlayedEpisode"
                component={PlayedEpisodeScreen}
              />
              <Stack.Screen name="Rank" component={RankScreen} />
              <Stack.Screen name="GameScreen" component={GameScreen} />
              <Stack.Screen name="Terms" component={TermsScreen} />
              <Stack.Screen name="Basket" component={BasketScreen} />
            </Stack.Group>
          ) : null}
          <Stack.Group screenOptions={{headerShown: false}}>
            <Stack.Screen name="Welcome" component={WelcomeScreen} />
            {otp ? (
              <Stack.Screen
                name="OTP"
                component={OTPScreen}
                options={{
                  title: translations.screens.otp,
                }}
              />
            ) : null}
            <Stack.Screen
              name="SignUp"
              component={SignUpScreen}
              options={{
                title: translations.screens.SignUp,
              }}
            />
          </Stack.Group>
        </>
      )}
    </Stack.Navigator>
  );
};

export default RootStack;

const headerStyle = (theme: Theme) => css`
  background-color: ${theme.colors.background};
`;

const headerTitleStyle = (theme: Theme) => css`
  font-family: '${theme.fonts.labelLarge?.fontFamily}';
  font-size: ${theme.fonts.labelMedium?.fontSize}px;
`;
