import {memo} from 'react';
import {Divider} from 'react-native-paper';
import styled from '@emotion/native';

import Loading from '@src/components/atoms/Loading';
import type {GameRankingsQuery} from '@src/data/generated';
import {spacing} from '@src/theme/units';

import Row from './Row';

type Props = {
  data: GameRankingsQuery['rankings'];
  loading?: boolean;
};

const Table = memo(({data, loading}: Props) => {
  if (loading) {
    return (
      <LoadingCard>
        <Loading />
      </LoadingCard>
    );
  }
  return (
    <Container showsVerticalScrollIndicator={false}>
      {data?.map((item, index) => (
        <>
          {index !== 0 && <CustomDivider />}
          <Row
            key={index}
            rank={item.rank}
            name={item.user.firstName ?? 'بدون نام'}
            score={item.score}
            avatar={item.user.avatar}
          />
        </>
      ))}
    </Container>
  );
});

export default Table;
const Container = styled.ScrollView(props => ({
  marginTop: spacing(3),
  flex: 1,
  backgroundColor: props.theme.colors.surface,
  borderRadius: props.theme.roundness * 6,
}));
const LoadingCard = styled.View(props => ({
  marginTop: spacing(3),
  backgroundColor: props.theme.colors.surface,
  borderRadius: props.theme.roundness * 6,
  height: 700,
}));

const CustomDivider = styled(Divider)(props => ({
  backgroundColor: props.theme.colors.onSurface,
  height: 1,
  width: '90%',
  alignSelf: 'center',
}));

// const data = [
//   {
//     rank: 1,
//     name: 'سارا نجفی',
//     score: 128399,
//     bullet: 10,
//     avatar: require('../../mock/chracter.jpg'),
//   },
//   {
//     rank: 2,
//     name: 'سارا',
//     score: 127399,
//     bullet: 9,
//     avatar: require('../../mock/chracter.jpg'),
//   },
//   {
//     rank: 3,
//     name: 'مافیای وحشی',
//     score: 118399,
//     bullet: 8,
//     avatar: require('../../mock/chracter.jpg'),
//   },
//   {
//     rank: 4,
//     name: 'علی انصاری',
//     score: 108399,
//     bullet: 7,
//     avatar: require('../../mock/chracter.jpg'),
//   },
//   {
//     rank: 5,
//     name: 'مهران فیض',
//     score: 9399,
//     bullet: 6,
//     avatar: require('../../mock/chracter.jpg'),
//   },
//   {
//     rank: 6,
//     name: 'جک کوچولو',
//     score: 92399,
//     bullet: 3,
//     avatar: require('../../mock/chracter.jpg'),
//   },
//   {
//     rank: 7,
//     name: 'KinG_Asgar',
//     score: 90299,
//     bullet: 3,
//     avatar: require('../../mock/chracter.jpg'),
//   },
//   {
//     rank: 8,
//     name: 'اصفر ومپایر',
//     score: 4399,
//     bullet: 3,
//     avatar: require('../../mock/chracter.jpg'),
//   },
// ];
