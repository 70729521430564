import {memo, useCallback, useState} from 'react';
import type {SheetProps} from 'react-native-actions-sheet';
import ActionSheet, {SheetManager} from 'react-native-actions-sheet';
import {Text} from 'react-native-paper';
import styled, {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {useTheme} from '@emotion/react';
import {useAtomValue} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import BulletsCard from '@src/components/atoms/BulletsCard';
import Button from '@src/components/atoms/Button';
import MafiaCard from '@src/components/atoms/MafiaCard';
import SheetsID from '@src/components/organism/Sheets/SheetsID';
import {
  CurrentLiveGameAtom,
  refetchVotesAtom,
  remainedVoteAtom,
} from '@src/components/screens/Game/store';
import type {VoteGameInput} from '@src/data/generated';
import {useVoteGameMutation, VoteVersion} from '@src/data/generated';
import useSnackbar from '@src/hooks/useSnackbar';
import {spacing, spacingPX} from '@src/theme/units';
import translations from '@src/translations';

const MafiaSelectionSheet = ({sheetId}: SheetProps) => {
  const theme = useTheme();
  const {updateSnackbar} = useSnackbar();
  const game = useAtomValue(CurrentLiveGameAtom);
  const remainingBullets = useAtomValue(remainedVoteAtom);
  const refetchVotes = useUpdateAtom(refetchVotesAtom);

  const [, setVotes] = useVoteGameMutation();
  const [selectedMafia, setSelectedMafia] = useState<string[]>([]);

  const handleOnCardPress = (id: string) => {
    if (selectedMafia.includes(id)) {
      setSelectedMafia(selectedMafia.filter(i => i !== id));
      return;
    }
    if (selectedMafia.length >= 3) {
      return;
    } else {
      setSelectedMafia([...selectedMafia, id]);
    }
  };

  const handleCloseCard = useCallback(async () => {
    if (!game || !game.id) {
      return;
    }
    if (remainingBullets < selectedMafia.length) {
      updateSnackbar(
        translations.errors.error,
        translations.errors.notEnoughBullets,
      );
      return;
    }
    const argumentsData: Array<VoteGameInput> = [];
    // Loop to generate shooting objects
    for (let i = 0; i < selectedMafia.length; i++) {
      argumentsData.push({
        version: VoteVersion.Shooting,
        action: VoteVersion.Shooting,
        playerID: selectedMafia[i],
        meta: 'mafia',
      });
    }
    // Send mutation
    const res = await setVotes({gameID: game.id, votes: argumentsData});
    if (res.error) {
      if (res.error.message.includes('votable')) {
        updateSnackbar(
          translations.errors.error,
          translations.errors.votingTimeOver,
        );
        await SheetManager.hide(SheetsID.mafiaSelectionSheet);
      } else {
        updateSnackbar(translations.errors.error, res.error.message);
      }
    } else {
      refetchVotes(true);
      await SheetManager.hide(SheetsID.mafiaSelectionSheet);
    }
  }, [
    game,
    refetchVotes,
    remainingBullets,
    selectedMafia,
    setVotes,
    updateSnackbar,
  ]);

  return (
    <ActionSheet
      id={sheetId}
      containerStyle={containerStyle(theme)}
      useBottomSafeAreaPadding
      animated>
      <Container>
        <Title variant="titleLarge">مافیا کیست؟</Title>
        <Desc variant="titleSmall">
          مافیا ها را انتخاب کنید و در صورت اطمینان ثبت کنید
        </Desc>
        <MafiaCardContainer>
          {game?.players?.map((player, index) => (
            <MafiaCard
              key={index}
              name={player.name}
              avatar={player.avatar}
              selected={selectedMafia.includes(player.id)}
              isDead={!player.isAlive}
              onCardPress={() => handleOnCardPress(player.id)}
            />
          ))}
        </MafiaCardContainer>
      </Container>
      <BulleteContainer>
        <BulletsCard bullets={remainingBullets} />
      </BulleteContainer>
      <CButton mode="contained" onPress={handleCloseCard}>
        <ButtonText variant="titleMedium">
          (۳/{selectedMafia.length.toLocaleString('fa')}) ثبت پیش بینی
        </ButtonText>
      </CButton>
    </ActionSheet>
  );
};

export default memo(MafiaSelectionSheet);

const Container = styled.ScrollView({
  flex: 1,
  maxWidth: 450,
  marginHorizontal: 'auto',
});

const Title = styled(Text)`
  text-align: center;
  color: ${({theme}) => theme.colors.primary};
`;
const Desc = styled(Text)`
  text-align: center;
  margin-top: ${spacingPX(1)};
`;

const containerStyle = (theme: Theme) => css`
  width: 100%;
  height: 600px;
  background-color: ${theme.colors.surface};
  padding: ${spacingPX(4) + ' ' + spacingPX(3)};
`;

const CButton = styled(Button)`
  width: 100%;
  margin-bottom: ${spacingPX(2)};
`;

const MafiaCardContainer = styled.View({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: spacing(2),
  maxWidth: 300,
  // eslint-disable-next-line max-lines
});

const BulleteContainer = styled.View({
  marginBottom: spacing(2),
});

const ButtonText = styled(Text)(({theme}) => ({
  color: theme.colors.background,
  textAlign: 'center',
}));
