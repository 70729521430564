import {View} from 'react-native';
import {Text} from 'react-native-paper';
import styled, {css} from '@emotion/native/dist/emotion-native.cjs';

import BulletIcon from '@src/components/atoms/BulletIcon';
import {spacing} from '@src/theme/units';

type Props = {
  bullets: number;
};

const BulletsCard = ({bullets}: Props) => (
  <BulletRow>
    <AmmoText variant="headlineLarge">{bullets.toLocaleString('fa')}</AmmoText>
    {Array(4)
      .fill(null)
      .map((_, index) => (
        <View style={bulletStyle(index)} key={index}>
          <BulletIcon
            active={bullets > 4 ? true : bullets % 4 > index}
            key={index}
          />
        </View>
      ))}
  </BulletRow>
);
export default BulletsCard;

const BulletRow = styled.View({flexDirection: 'row', alignItems: 'center'});
const bulletStyle = (index: number) =>
  css({
    transform: [{translateX: -17 * index}],
  });
const AmmoText = styled(Text)(({theme}) => ({
  color: theme.colors.primary,
  marginTop: spacing(0.5),
}));
