import {useCallback} from 'react';
import {ProgressBar as PaperProgressBar} from 'react-native-paper';
import styled from '@emotion/native/dist/emotion-native.cjs';
import {useTheme} from '@emotion/react';
import Slider from '@react-native-community/slider';
import {useAtomValue} from 'jotai';

import {
  bufferingAtom,
  durationAtom,
  playerRefAtom,
  progressAtom,
} from '@src/components/molocules/Player/controls.store';
import {spacing} from '@src/theme/units';

type Props = {
  isLive?: boolean;
};

const ProgressBar = ({isLive}: Props) => {
  const buffering = useAtomValue(bufferingAtom);
  const progress = useAtomValue(progressAtom);
  const duration = useAtomValue(durationAtom);
  const playerRef = useAtomValue(playerRefAtom);

  const theme = useTheme();

  const handleSeekTo = useCallback(
    (value: number /* [1-100] */) => {
      if (playerRef?.current) {
        playerRef.current.seekTo(value, 'seconds');
      }
    },
    [playerRef],
  );

  return (
    <ProgressBarContainer>
      {buffering || isLive ? (
        <CustomProgressBar indeterminate={buffering} progress={progress} />
      ) : (
        <Slider
          nativeID="progressSlider"
          minimumTrackTintColor={theme.colors.primary}
          maximumTrackTintColor={theme.colors.onBackground}
          minimumValue={1}
          maximumValue={duration}
          value={progress}
          onSlidingComplete={handleSeekTo}
        />
      )}
    </ProgressBarContainer>
  );
};

export default ProgressBar;

const CustomProgressBar = styled(PaperProgressBar)(() => ({}));
const ProgressBarContainer = styled.View({
  flex: 1,
  width: '70%',
  marginHorizontal: spacing(2),
});
