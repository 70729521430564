import Svg, {G, Path} from 'react-native-svg';

type Props = {active?: boolean};

const BulletIcon = ({active = false}: Props) => (
  <Svg width="39" height="39" viewBox="0 0 39 39" fill="none">
    <G clipPath="url(#clip0_186_1855)">
      <Path
        d="M15.4091 29.5345L25.0399 19.9037C25.2175 19.7262 25.2174 19.4392 25.0399 19.2617L19.2614 13.4832C19.0839 13.3057 18.7969 13.3057 18.6194 13.4832L8.98864 23.114C8.8111 23.2916 8.81114 23.5785 8.98865 23.756L8.66764 24.077C8.49009 23.8995 8.20312 23.8995 8.02562 24.077L6.7415 25.3612C6.56396 25.5387 6.564 25.8257 6.7415 26.0032L12.52 31.7817C12.6975 31.9592 12.9845 31.9592 13.162 31.7817L14.446 30.4976C14.6236 30.3201 14.6235 30.0331 14.446 29.8556L14.767 29.5346C14.9446 29.7121 15.2316 29.7121 15.4091 29.5345Z"
        fill={active ? '#F4D144' : '#515151'}
      />
      <Path
        d="M20.2244 13.1622L25.3608 18.2986C25.5384 18.4761 25.8254 18.4761 26.0029 18.2986L26.3239 17.9776C28.4343 15.8672 29.442 12.8428 29.0205 9.88771C28.9919 9.68772 28.8353 9.53106 28.6353 9.50248C25.6802 9.081 22.6558 10.0887 20.5454 12.1991L20.2244 12.5201C20.0469 12.6977 20.0469 12.9846 20.2244 13.1622Z"
        fill={active ? '#F4D144' : '#515151'}
      />
    </G>
  </Svg>
);
export default BulletIcon;
