const common = {
  login: 'ورود به اکانت کاربری',
  enterOTP: 'کد تایید را وارد کنید',
  enterCode_1: 'لطفا کد ارسال شده به شماره موبایل',
  enterCode_2: ' ارسال شده است را وارد کنید',
  resentTime: 'زمان باقی مانده تا ارسال مجدد رمز:',
  didntGetCode: 'هنوز کد را دریافت نکردی؟',
  resendCode: 'ارسال مجدد کد',
  changePhone: 'تغییر شماره',
  discount: 'تخفیف',
  selectAddress: 'انتخاب آدرس',
  showMore: 'مشاهده همه',
  version: 'نسخه',
  choiceAddress: 'انتخاب آدرس',
  searchAllay: 'جستجو محله ...',
  areYouSureOfYourDecision: 'آیا از تصمیم خود مطمئن هستید؟',
  deliveredTo: 'تحویل به',
  noCommentsYet: 'هنوز به این سفارش امتیاز نداده‌اید',
  rateNow: 'ثبت نظر',
  lastSearch: 'آخرین جست و جو ها',
  notFoundTitle: 'نتیجه ای برای جست و جو یافت نشد',
  notFoundText:
    'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با چاپگرها و متون استفاده از طراحان گرافیک است.',
  popular: 'پرطرفدار',
  allCategories: 'همه دسته‌بندی ها',
  km: 'کیلومتر',
  iranMallDelivery: 'ارسال ایرانمال',
  minute: 'دقیقه',
  until: 'تا',
  basket: 'سبد خرید',
  yourOrders: 'سفارش شما',
  description: 'توضیحات',
  orderInvoice: 'فاکتور سفارش',
  totalOrderPrice: 'جمع سفارش',
  profit: 'سود شما از این خرید',
  priceAfterDiscount: 'مجموع اقلام پس از تخفیف',
  tax: 'مالیات',
  deliveryPrice: 'هزینه ارسال',
  delivery: 'ارسال',
  totalPayablePrice: 'مبلغ قابل پرداخت',
  toman: 'تومان',
  confirmAndPay: 'تایید نهایی و پرداخت',
  deliveryAddress: 'آدرس تحویل سفارش',
  payMethod: ' روش پرداخت',
  discountCode: 'افزودن کد تخفیف',
  commentText: 'نظرات خود را از آخرین سفارش پرپروک در میان بگذارید',
  commentPlaceHolder: 'نظر خود را بنویسید ...',
  sendComment: 'ارسال نظر',
  paidPrice: 'مبلغ پرداخت شده',
  deliverd: 'تحویل دادیم',
  addNewAddress: 'افزودن آدرس جدید',
  tehran: 'تهران',
  address: 'آدرس',
  confirmAddress: 'لطفا از درستی آدرس فوق مطمئن شوید',
  requiredAddress: 'عنوان آدرس اجباری هست',
  addressSubmitted: 'آدرس شما با موفقیت ثبت شد',
  filters: 'فیلتر ها',
  accessToYourLocation: 'دسترسی به موقعیت شما',
  accessLocationDescription:
    'برای پیدا کردن شما برنامه نیاز به دسترسی به محل حضور شما و کار کردن روان تر برنامه نیاز به موقعیت فعلی شما دارد.',
  accessPermission: 'اجازه دسترسی',
  requestCancel: 'لغو درخواست',
  MafiaAnswer: 'پاسخ مالاتو',
  usersComment: 'نظرات کاربران',
  saturdayToWednesday: 'شنبه تا چهارشنبه',
  weekend: 'پنجشنبه و جمعه',
  timeOne: '۱۰:۰۰ - ۲۲:۰۰',
  timeTwo: '۹:۰۰ - ۰۰:۰۰',
  information: 'اطلاعات',
  comments: 'نظرات',
  sortBy: 'مرتب سازی براساس ...',
  sortFromWithDot: 'به ترتیب از ...',
  sortFrom: 'به ترتیب از',
  openRestaurantFor: 'رستوران باز برای ',
  addAddAddress: 'افزودن آدرس جدید',
  sendCourier: 'ارسال پیک ',
  MafiaApp: 'اپلیکیشن مالاتو',
  myAddress: 'آدرس های من',
  faq: 'سوالات متداول',
  logOutAccount: 'خروج',
  areYouSureLogOut: 'آیا مطمئن هستید که میخواهید از اپ خارج شوید؟',
  not_implemented: 'به زودی در دسترس قرار می‌گیرد',
  purchaseHistory: 'تاریخچه ‌خرید',
  clock: 'ساعت',
  wallet: 'کیف پول',
  chargeWallet: 'شارژ کردن کیف پول',
  deliveryTo: 'تحویل به',
  allTags: 'دسته بندی ها',
  discountTextCopied: '!کد تخفیف کپی شد',
  copied: 'کپی شد!',
  successChargeText: 'شارژ حساب شما با موفقیت انجام شد',
  free: 'رایگان',
  order: 'سفارش',
  whereShouldDeliver: 'به کجا ارسال بشه؟',
  chargedWallet: 'شارژ‌ کیف پول',
  deposit: 'واریز',
  withdraw: 'برداشت پول',
  yourAmount: 'موجودی شما',
  emptyBasket: '.سبد خرید شما خالی است',
  receiverName: 'نام گیرنده سفارش',
  goHomePage: 'رفتن به صفحه اصلی',
  choiceAvatar: 'انتخاب آواتار',
  live: 'زنده',
  person: 'نفر',
  players: 'بازیکن‌ها',
  episode: 'قسمت ',
  mafiaBazi: 'مافیا‌ بازی',
  yourScore: 'امتیاز‌ شما در این بازی',
  remainingBullet: 'تعداد تیر باقی مانده',
  nightWatch: 'تماشای شب ها',
  nightOne: 'روز ۱',
  nightTwo: 'روز ۲',
  nightThree: 'روز ۳',
  nightFour: 'روز ۴',
  termsAndConditions: 'قوانین و مقررات',
  setting: 'تنظیمات',
  previousMatches: 'مسابقه های قبلی',
  comingSoon: 'به زودی',
  mafias: 'مافیاها',
  yourScoreFromGame: 'امتیاز شما از این بازی',
  goodNight: 'روز شما بخیر',
  bulletNumber: 'تعداد گلوله',
  totalScore: 'امتیاز کل',
  lastGame: 'آخرین بازی',
  lastWeek: 'هفته گذشته',
  codeSent: 'کد تایید به شماره موبایل شما ارسال شد',
  specialOffer: 'پیشنهاد ویژه',
  amountPayable: 'مبلغ قابل پرداخت',
  buyBullet: 'خرید گلوله',
  buyBulletText:
    'برای دیدن مافیا بازی بسته مورد نیازتون را انتخاب کنید و از تماشای بازی لذت ببرید',
  citizens: 'شهروندها',
  mafia: 'مافیا',
  night: 'شب',
  successfullySent: 'با موفقیت ارسال شد',
  welcome: 'خوش آمدید',
  day: 'روز',
};

export default common;
