import {useCallback, useRef, useState} from 'react';
import {Image, ImageBackground} from 'react-native';
import type {TextInputProps} from 'react-native-paper';
import {Button} from 'react-native-paper';
import styled, {css} from '@emotion/native';
import {useTheme} from '@emotion/react';
import {digitsEnToFa, digitsFaToEn} from '@persian-tools/persian-tools';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import {useSetAtom} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import {otpAtom, usernameAtom} from '@src/auth/store';
import TextInput from '@src/components/atoms/TextInput';
import {phoneNumberRegex} from '@src/configuration';
import {useRequestVerificationCodeMutation} from '@src/data/generated';
import useSnackbar from '@src/hooks/useSnackbar';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type mainScreenProp = StackNavigationProp<RootStackParamList, 'OTP'>;

const WelcomeScreen = () => {
  const theme = useTheme();
  const setOtp = useSetAtom(otpAtom);
  const numberRef = useRef<TextInputType | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
  const [requestResults, sendRequest] = useRequestVerificationCodeMutation();
  const {updateSnackbar} = useSnackbar();
  const setUsername = useUpdateAtom(usernameAtom);
  const {navigate} = useNavigation<mainScreenProp>();

  const handlePhoneNumberValidation = useCallback((text: string) => {
    setPhoneNumber(digitsFaToEn(text));
    if (RegExp(phoneNumberRegex).test(digitsFaToEn(text))) {
      setValidPhoneNumber(true);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    if (validPhoneNumber) {
      try {
        setOtp(true);
        setUsername(phoneNumber);
        const result = await sendRequest({phoneNumber});
        if (result?.data?.requestVerificationCode) {
          navigate('OTP');
        }
      } catch (e: any) {
        updateSnackbar(translations.errors.error, e.errors[0].message);
      }
    }
  }, [
    navigate,
    phoneNumber,
    sendRequest,
    setOtp,
    setUsername,
    updateSnackbar,
    validPhoneNumber,
  ]);
  return (
    <Container>
      <ImageBackground
        source={require('@src/assets/images/Background.png')}
        resizeMode="cover"
        resizeMethod="auto"
        style={backgroundImageStyle}>
        <LogoContainer>
          <Image
            source={require('@src/assets/images/logo.png')}
            style={imageStyle}
          />
        </LogoContainer>
        <ContentContainer>
          <TextInput
            nativeID={
              !validPhoneNumber && phoneNumber.length > 0
                ? 'inputError'
                : undefined
            }
            ref={numberRef}
            label={translations.label.phoneNumber}
            textContentType="telephoneNumber"
            keyboardType="number-pad"
            onSubmitEditing={handleSubmit}
            value={digitsEnToFa(phoneNumber)}
            onChangeText={handlePhoneNumberValidation}
            autoFocus
            placeholder={translations.placeholders.simpleNumber}
          />
          <SendButton
            loading={requestResults.fetching}
            mode={'contained'}
            disabled={!validPhoneNumber}
            onPress={handleSubmit}
            textColor={theme.colors.onPrimary}>
            {translations.buttons.enterGame}
          </SendButton>
        </ContentContainer>
      </ImageBackground>
    </Container>
  );
};

export default WelcomeScreen;

type TextInputType = TextInputProps & {
  error?: boolean;
};

const Container = styled.View({
  flex: 1,
  height: '100%',
  width: '100%',
});

const backgroundImageStyle = css({
  flex: 1,
});

const LogoContainer = styled.View({
  flex: 6,
  alignItems: 'center',
  marginTop: '30%',
});

const ContentContainer = styled.View({
  marginHorizontal: 'auto',
  width: '90%',
  marginBottom: spacing(5),
});
const imageStyle = css({width: 271, height: 59, resizeMode: 'contain'});

const SendButton = styled(Button)(({theme}) => ({
  padding: 6,
  marginTop: spacing(3),
  color: theme.colors.background,
  fontWeight: '600',
}));
