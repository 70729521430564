const errors = {
  OtpError: 'کد اشتباه است',
  wentWrong: 'اوه!مشکلی پیش اومد',
  gameNotStarted: 'بازی هنوز شروع نشده',
  gameNotStartedORFinished: 'بازی هنوز شروع نشده یا تموم شده',
  error: 'خطا',
  gameFinished: 'بازی تموم شده',
  notEnoughBullets: 'گلوله کافی نداری',
  votingTimeOver: 'زمان رای گیری تموم شده',
};

export default errors;
