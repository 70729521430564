import {View} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import Button from '@src/components/atoms/Button';
import Container from '@src/components/atoms/Container';
import Counter from '@src/components/atoms/Counter';
import ItemCard from '@src/components/atoms/ItemCard';
import useCountdown from '@src/hooks/useCountdown';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import Header from './Header';

const Basket = () => {
  const targetDate = new Date('2023-10-9');

  const timeLeft = useCountdown(targetDate);

  return (
    <Container>
      <Header />
      <Main>
        <CounterContainer>
          <Counter timeLeft={timeLeft} />
        </CounterContainer>
        <DescriptionCard>
          <DescriptionText variant={'bodyMedium'}>
            برای دیدن مافیا بازی بسته مورد نیازتون را انتخاب کنید و از تماشای
            بازی لذت ببرید
          </DescriptionText>
        </DescriptionCard>
        <ItemsContainer>
          {bulletItems.map((item, index) => (
            <ItemCard item={item} key={index} />
          ))}
        </ItemsContainer>
      </Main>
      <CustomBtn mode={'contained'}>{translations.common.buyBullet}</CustomBtn>
    </Container>
  );
};

export default Basket;

const CounterContainer = styled(View)({
  marginTop: spacing(5),
});

const Main = styled.View({flex: 1});

const DescriptionCard = styled.View(props => ({
  backgroundColor: props.theme.colors.surface,
  paddingHorizontal: spacing(4.2),
  paddingVertical: spacing(1.2),
  borderRadius: props.theme.roundness * 6,
  marginVertical: spacing(4),
}));
const DescriptionText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  textAlign: 'center',
}));

const ItemsContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: spacing(2.5),
});

const CustomBtn = styled(Button)({});

const bulletItems = [
  {
    image: require('@src/assets/images/bullet1.png'),
    entity: 20,
    title: 'تماشای ۱ بازی',
    desc: 'قیمت هر بازی ۱۲۰ هزار تومان',
    price: 120000,
    discount: 100000,
    premium: false,
  },
  {
    image: require('@src/assets/images/bullet2.png'),
    entity: 40,
    title: 'تماشای ۲ بازی',
    desc: 'قیمت هر بازی ۱۲۰ هزار تومان',
    price: 240000,
    discount: 200000,
    premium: true,
  },
  {
    image: require('@src/assets/images/bullet3.png'),
    entity: 160,
    title: 'تماشای ۴ بازی',
    desc: 'قیمت هر بازی ۱۲۰ هزار تومان',
    price: 480000,
    discount: 450000,
    premium: false,
  },
];
