import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Cursor: {input: any; output: any};
  Map: {input: any; output: any};
  MapBool: {input: any; output: any};
  MapString: {input: any; output: any};
  Time: {input: any; output: any};
  Upload: {input: any; output: any};
  _Any: {input: any; output: any};
  _FieldSet: {input: any; output: any};
};

/**
 * CreateGameInput is used for create Game object.
 * Input was generated by ent.
 */
export type CreateGameInput = {
  banner?: InputMaybe<Scalars['String']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  currentRound?: InputMaybe<Scalars['Int']['input']>;
  defaultVotes?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  episode?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<GameStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<GameVersion>;
  winner?: InputMaybe<GameWinner>;
};

/**
 * CreateParticipantInput is used for create Participant object.
 * Input was generated by ent.
 */
export type CreateParticipantInput = {
  gameID: Scalars['ID']['input'];
  userID: Scalars['ID']['input'];
};

/**
 * CreatePlayerInput is used for create Player object.
 * Input was generated by ent.
 */
export type CreatePlayerInput = {
  avatar: Scalars['String']['input'];
  gameID: Scalars['ID']['input'];
  isAlive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  roleID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateRoleInput is used for create Role object.
 * Input was generated by ent.
 */
export type CreateRoleInput = {
  description: Scalars['String']['input'];
  gameID: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  type?: InputMaybe<RoleType>;
};

/**
 * CreateUserInput is used for create User object.
 * Input was generated by ent.
 */
export type CreateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['MapBool']['input']>;
  phone: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type FileUploadInput = {
  content: Scalars['Upload']['input'];
  section: Section;
};

export type FilterGames = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GameStatus>;
  statusIn?: InputMaybe<Array<GameStatus>>;
};

export type FilterParticipants = {
  gameID?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type FilterPlayers = {
  gameID?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type FilterRoles = {
  gameID?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type FilterUsers = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type Game = Node & {
  __typename?: 'Game';
  archiveDashURL?: Maybe<Scalars['String']['output']>;
  archiveHlsURL?: Maybe<Scalars['String']['output']>;
  archiveID?: Maybe<Scalars['String']['output']>;
  banner?: Maybe<Scalars['String']['output']>;
  cover?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  currentRound: Scalars['Int']['output'];
  defaultVotes: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  episode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  participantsCount: Scalars['Int']['output'];
  players?: Maybe<Array<Player>>;
  roles?: Maybe<Array<Role>>;
  status: GameStatus;
  streamDashURL?: Maybe<Scalars['String']['output']>;
  streamHlsURL?: Maybe<Scalars['String']['output']>;
  streamID?: Maybe<Scalars['String']['output']>;
  streamInput?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  version: GameVersion;
  winner?: Maybe<GameWinner>;
};

/** Ordering options for Game connections */
export type GameOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Games. */
  field: GameOrderField;
};

/** Properties by which Game connections can be ordered. */
export enum GameOrderField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

/** GameStatus is enum for the field status */
export enum GameStatus {
  Created = 'created',
  Finished = 'finished',
  InProgress = 'in_progress',
  Preparing = 'preparing',
  Voting = 'voting',
}

/** GameVersion is enum for the field version */
export enum GameVersion {
  Shooting = 'shooting',
}

/** GameWinner is enum for the field winner */
export enum GameWinner {
  Citizen = 'citizen',
  Mafia = 'mafia',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Copy a game with its roles & players **(admin only)** */
  copyGame: Game;
  /** Create a new game **(admin only)** */
  createGame: Game;
  /** Create a new participant **(admin only)** */
  createParticipant: Participant;
  /** Create a new player **(admin only)** */
  createPlayer: Player;
  /** Create a new role **(admin only)** */
  createRole: Role;
  /** Create a new user **(admin only)** */
  createUser: User;
  /** Delete a game **(admin only)** */
  deleteGame: Scalars['Boolean']['output'];
  /** Delete a participant **(admin only)** */
  deleteParticipant: Scalars['Boolean']['output'];
  /** Delete a player **(admin only)** */
  deletePlayer: Scalars['Boolean']['output'];
  /** Delete a role **(admin only)** */
  deleteRole: Scalars['Boolean']['output'];
  /** Delete a user **(admin only)** */
  deleteUser: Scalars['Boolean']['output'];
  /** Join a game **(authenticated only)** */
  joinGame: Participant;
  login: Token;
  noop: Scalars['String']['output'];
  refreshToken: Token;
  requestVerificationCode: Scalars['Boolean']['output'];
  /** Update a game **(admin only)** */
  updateGame: Game;
  /** Update the remaining votes of a game **(admin only)** */
  updateGameParticipantsRemainingVotes: Scalars['Boolean']['output'];
  /** Update a participant **(admin only)** */
  updateParticipant: Participant;
  /** Update a player **(admin only)** */
  updatePlayer: Player;
  /** Update profile **(authenticated only)** */
  updateProfile: User;
  /** Update a role **(admin only)** */
  updateRole: Role;
  /** Update a user **(admin only)** */
  updateUser: User;
  uploadFile: UploadResult;
  /** Leave a game **(authenticated only)** */
  voteGame?: Maybe<Array<Vote>>;
};

export type MutationCopyGameArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCreateGameArgs = {
  createGameInput: CreateGameInput;
};

export type MutationCreateParticipantArgs = {
  CreateParticipantInput: CreateParticipantInput;
};

export type MutationCreatePlayerArgs = {
  createPlayerInput: CreatePlayerInput;
};

export type MutationCreateRoleArgs = {
  createRoleInput: CreateRoleInput;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type MutationDeleteGameArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteParticipantArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePlayerArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationJoinGameArgs = {
  id: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
  phone: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};

export type MutationRequestVerificationCodeArgs = {
  phone: Scalars['String']['input'];
};

export type MutationUpdateGameArgs = {
  id: Scalars['ID']['input'];
  updateGameInput: UpdateGameInput;
};

export type MutationUpdateGameParticipantsRemainingVotesArgs = {
  id: Scalars['ID']['input'];
  remainingVotes: Scalars['Int']['input'];
};

export type MutationUpdateParticipantArgs = {
  id: Scalars['ID']['input'];
  updateParticipantInput: UpdateParticipantInput;
};

export type MutationUpdatePlayerArgs = {
  id: Scalars['ID']['input'];
  updatePlayerInput: UpdatePlayerInput;
};

export type MutationUpdateProfileArgs = {
  updateProfileInput: UpdateUserInput;
};

export type MutationUpdateRoleArgs = {
  id: Scalars['ID']['input'];
  updateRoleInput: UpdateRoleInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  updateUserInput: UpdateUserInput;
};

export type MutationUploadFileArgs = {
  input: FileUploadInput;
};

export type MutationVoteGameArgs = {
  id: Scalars['ID']['input'];
  votes: Array<VoteGameInput>;
};

export type MyGames = {
  __typename?: 'MyGames';
  game: Game;
  participant: Participant;
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output'];
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = 'DESC',
}

/**
 * Information about pagination in a connection.
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type Participant = Node & {
  __typename?: 'Participant';
  createdAt: Scalars['Time']['output'];
  game: Game;
  gameID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  remainingVotes: Scalars['Int']['output'];
  score: Scalars['Int']['output'];
  scoreReport?: Maybe<ScoreReport>;
  updatedAt: Scalars['Time']['output'];
  user: User;
  userID: Scalars['ID']['output'];
};

/** Ordering options for Participant connections */
export type ParticipantOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Participants. */
  field: ParticipantOrderField;
};

/** Properties by which Participant connections can be ordered. */
export enum ParticipantOrderField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type Player = Node & {
  __typename?: 'Player';
  avatar: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  game: Game;
  gameID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isAlive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  role?: Maybe<Role>;
  roleID?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['Time']['output'];
};

/** Ordering options for Player connections */
export type PlayerOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Players. */
  field: PlayerOrderField;
};

/** Properties by which Player connections can be ordered. */
export enum PlayerOrderField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type PublicProfile = {
  __typename?: 'PublicProfile';
  avatar?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  monthlyScore: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalScore: Scalars['Int']['output'];
  weeklyScore: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  /** Get a game by id */
  game: Game;
  /** Get ranking of a specific game */
  gameRankings?: Maybe<Array<Rank>>;
  /** Get all games */
  games?: Maybe<Array<Game>>;
  /** Get the number of games */
  gamesCount: Scalars['Int']['output'];
  /** Get my ranking and all the users around me for a specific game */
  myGameRankings?: Maybe<Array<Rank>>;
  /** Get the games of the authenticated user */
  myGames?: Maybe<Array<MyGames>>;
  /** Get the number of games of the authenticated user */
  myGamesCount: Scalars['Int']['output'];
  /** Get my ranking and all the users around me */
  myRankings?: Maybe<Array<Rank>>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Get a participant by ID **(admin only)** */
  participant: Participant;
  /** Get all participants **(admin only)** */
  participants?: Maybe<Array<Participant>>;
  /** Get the number of participants **(admin only)** */
  participantsCount: Scalars['Int']['output'];
  /** Get a player by ID **(admin only)** */
  player: Player;
  /** Get all players **(admin only)** */
  players?: Maybe<Array<Player>>;
  /** Get the number of players **(admin only)** */
  playersCount: Scalars['Int']['output'];
  /** Get the current user's profile **(authenticated only)** */
  profile: User;
  /** Get general ranking */
  rankings?: Maybe<Array<Rank>>;
  /** Get a role by ID */
  role: Role;
  /** Get all roles */
  roles?: Maybe<Array<Role>>;
  /** Get number of roles */
  rolesCount: Scalars['Int']['output'];
  /** Get a user by id **(admin only)** */
  user: User;
  /** Get all users **(admin only)** */
  users?: Maybe<Array<User>>;
  /** Get the number of users **(admin only)** */
  usersCount: Scalars['Int']['output'];
};

export type QueryGameArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGameRankingsArgs = {
  gameId: Scalars['ID']['input'];
};

export type QueryGamesArgs = {
  filter?: InputMaybe<FilterGames>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryGamesCountArgs = {
  filter?: InputMaybe<FilterGames>;
};

export type QueryMyGameRankingsArgs = {
  gameId: Scalars['ID']['input'];
};

export type QueryMyGamesArgs = {
  filter?: InputMaybe<FilterGames>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryMyGamesCountArgs = {
  filter?: InputMaybe<FilterGames>;
};

export type QueryMyRankingsArgs = {
  type: RankingType;
};

export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryParticipantArgs = {
  id: Scalars['ID']['input'];
};

export type QueryParticipantsArgs = {
  filter?: InputMaybe<FilterParticipants>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryParticipantsCountArgs = {
  filter?: InputMaybe<FilterParticipants>;
};

export type QueryPlayerArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPlayersArgs = {
  filter?: InputMaybe<FilterPlayers>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryPlayersCountArgs = {
  filter?: InputMaybe<FilterPlayers>;
};

export type QueryRankingsArgs = {
  type: RankingType;
};

export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRolesArgs = {
  filter?: InputMaybe<FilterRoles>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryRolesCountArgs = {
  filter?: InputMaybe<FilterRoles>;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<FilterUsers>;
  pagination?: InputMaybe<Pagination>;
};

export type QueryUsersCountArgs = {
  filter?: InputMaybe<FilterUsers>;
};

export type Rank = {
  __typename?: 'Rank';
  rank: Scalars['Int']['output'];
  score: Scalars['Int']['output'];
  user: PublicProfile;
};

export enum RankingType {
  LastGame = 'LastGame',
  Monthly = 'Monthly',
  Total = 'Total',
  Weekly = 'Weekly',
}

export type Role = Node & {
  __typename?: 'Role';
  createdAt: Scalars['Time']['output'];
  description: Scalars['String']['output'];
  game: Game;
  gameID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: RoleType;
  updatedAt: Scalars['Time']['output'];
};

/** Ordering options for Role connections */
export type RoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Roles. */
  field: RoleOrderField;
};

/** Properties by which Role connections can be ordered. */
export enum RoleOrderField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

/** RoleType is enum for the field type */
export enum RoleType {
  Citizen = 'citizen',
  Mafia = 'mafia',
}

export type RoundReport = {
  __typename?: 'RoundReport';
  correct: Scalars['Int']['output'];
  incorrect: Scalars['Int']['output'];
  round: Scalars['Int']['output'];
};

export type ScoreReport = {
  __typename?: 'ScoreReport';
  rounds: Array<RoundReport>;
};

export enum Section {
  GameBanner = 'GameBanner',
  GameCover = 'GameCover',
  GameThumbnail = 'GameThumbnail',
  PlayerAvatar = 'PlayerAvatar',
}

export type Subscription = {
  __typename?: 'Subscription';
  currentTime: Scalars['String']['output'];
  gameCreated: Game;
  gameLiveCount: Scalars['Int']['output'];
  gameUpdated: Game;
};

export type SubscriptionGameLiveCountArgs = {
  id: Scalars['ID']['input'];
};

export type SubscriptionGameUpdatedArgs = {
  id: Scalars['ID']['input'];
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
};

/**
 * UpdateGameInput is used for update Game object.
 * Input was generated by ent.
 */
export type UpdateGameInput = {
  banner?: InputMaybe<Scalars['String']['input']>;
  clearBanner?: InputMaybe<Scalars['Boolean']['input']>;
  clearCover?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearEpisode?: InputMaybe<Scalars['Boolean']['input']>;
  clearSubtitle?: InputMaybe<Scalars['Boolean']['input']>;
  clearThumbnail?: InputMaybe<Scalars['Boolean']['input']>;
  clearWinner?: InputMaybe<Scalars['Boolean']['input']>;
  cover?: InputMaybe<Scalars['String']['input']>;
  currentRound?: InputMaybe<Scalars['Int']['input']>;
  defaultVotes?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  episode?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<GameStatus>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<GameVersion>;
  winner?: InputMaybe<GameWinner>;
};

/**
 * UpdateParticipantInput is used for update Participant object.
 * Input was generated by ent.
 */
export type UpdateParticipantInput = {
  gameID?: InputMaybe<Scalars['ID']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdatePlayerInput is used for update Player object.
 * Input was generated by ent.
 */
export type UpdatePlayerInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  clearRole?: InputMaybe<Scalars['Boolean']['input']>;
  gameID?: InputMaybe<Scalars['ID']['input']>;
  isAlive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roleID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateRoleInput is used for update Role object.
 * Input was generated by ent.
 */
export type UpdateRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gameID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<RoleType>;
};

/**
 * UpdateUserInput is used for update User object.
 * Input was generated by ent.
 */
export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  clearAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  clearFirstName?: InputMaybe<Scalars['Boolean']['input']>;
  clearLastName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  clearTitle?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Scalars['MapBool']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UploadResult = {
  __typename?: 'UploadResult';
  url: Scalars['String']['output'];
};

export type User = Node & {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  monthlyScore: Scalars['Int']['output'];
  participants?: Maybe<Array<Participant>>;
  permissions?: Maybe<Scalars['MapBool']['output']>;
  phone: Scalars['String']['output'];
  remainingVotes: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  totalScore: Scalars['Int']['output'];
  updatedAt: Scalars['Time']['output'];
  votes?: Maybe<Array<Vote>>;
  weeklyScore: Scalars['Int']['output'];
};

/** Ordering options for User connections */
export type UserOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Users. */
  field: UserOrderField;
};

/** Properties by which User connections can be ordered. */
export enum UserOrderField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

export type Vote = Node & {
  __typename?: 'Vote';
  action: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  game: Game;
  gameID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  meta: Scalars['String']['output'];
  player?: Maybe<Player>;
  playerID?: Maybe<Scalars['ID']['output']>;
  round: Scalars['Int']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
  userID: Scalars['ID']['output'];
  version: VoteVersion;
};

export type VoteGameInput = {
  action: Scalars['String']['input'];
  meta?: InputMaybe<Scalars['String']['input']>;
  playerID: Scalars['ID']['input'];
  version: Scalars['String']['input'];
};

/** Ordering options for Vote connections */
export type VoteOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Votes. */
  field: VoteOrderField;
};

/** Properties by which Vote connections can be ordered. */
export enum VoteOrderField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT',
}

/** VoteVersion is enum for the field version */
export enum VoteVersion {
  Shooting = 'shooting',
}

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

export type RequestVerificationCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
}>;

export type RequestVerificationCodeMutation = {
  __typename?: 'Mutation';
  requestVerificationCode: boolean;
};

export type LoginMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {__typename?: 'Token'; accessToken: string; refreshToken: string};
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: {
    __typename?: 'Token';
    accessToken: string;
    refreshToken: string;
  };
};

export type UpdateProfileMutationVariables = Exact<{
  updateProfileData: UpdateUserInput;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile: {__typename?: 'User'; id: string; avatar?: string | null};
};

export type JoinGameMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type JoinGameMutation = {
  __typename?: 'Mutation';
  joinGame: {__typename?: 'Participant'; score: number};
};

export type VoteGameMutationVariables = Exact<{
  gameID: Scalars['ID']['input'];
  votes: Array<VoteGameInput> | VoteGameInput;
}>;

export type VoteGameMutation = {
  __typename?: 'Mutation';
  voteGame?: Array<{__typename?: 'Vote'; round: number}> | null;
};

export type GamesQueryVariables = Exact<{
  filter?: InputMaybe<FilterGames>;
}>;

export type GamesQuery = {
  __typename?: 'Query';
  games?: Array<{
    __typename?: 'Game';
    id: string;
    title: string;
    subtitle?: string | null;
    episode?: string | null;
    description?: string | null;
    participantsCount: number;
    banner?: string | null;
    players?: Array<{
      __typename?: 'Player';
      name: string;
      avatar: string;
      role?: {__typename?: 'Role'; name: string; type: RoleType} | null;
    }> | null;
  }> | null;
};

export type OnlineGameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type OnlineGameQuery = {
  __typename?: 'Query';
  game: {
    __typename?: 'Game';
    id: string;
    title: string;
    episode?: string | null;
    description?: string | null;
    banner?: string | null;
    status: GameStatus;
    streamInput?: string | null;
    thumbnail?: string | null;
    streamHlsURL?: string | null;
    participantsCount: number;
    currentRound: number;
    players?: Array<{
      __typename?: 'Player';
      id: string;
      name: string;
      avatar: string;
      isAlive: boolean;
    }> | null;
  };
};

export type ProfileQueryVariables = Exact<{[key: string]: never}>;

export type ProfileQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'User';
    firstName?: string | null;
    avatar?: string | null;
    totalScore: number;
    monthlyScore: number;
    remainingVotes: number;
  };
};

export type PlayedGameQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PlayedGameQuery = {
  __typename?: 'Query';
  game: {
    __typename?: 'Game';
    title: string;
    episode?: string | null;
    description?: string | null;
    banner?: string | null;
    status: GameStatus;
    archiveHlsURL?: string | null;
    players?: Array<{
      __typename?: 'Player';
      name: string;
      avatar: string;
      role?: {__typename?: 'Role'; name: string} | null;
    }> | null;
  };
};

export type UserGameScoreQueryVariables = Exact<{[key: string]: never}>;

export type UserGameScoreQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'User';
    id: string;
    weeklyScore: number;
    remainingVotes: number;
    votes?: Array<{__typename?: 'Vote'; gameID: string; round: number}> | null;
    participants?: Array<{
      __typename?: 'Participant';
      score: number;
      remainingVotes: number;
      gameID: string;
    }> | null;
  };
};

export type UserVoteRemainedQueryVariables = Exact<{[key: string]: never}>;

export type UserVoteRemainedQuery = {
  __typename?: 'Query';
  profile: {__typename?: 'User'; remainingVotes: number};
};

export type MyGameQueryVariables = Exact<{
  filter?: InputMaybe<FilterGames>;
  pagination?: InputMaybe<Pagination>;
}>;

export type MyGameQuery = {
  __typename?: 'Query';
  myGames?: Array<{
    __typename?: 'MyGames';
    participant: {__typename?: 'Participant'; remainingVotes: number};
  }> | null;
};

export type MyGameVoteDetailsQueryVariables = Exact<{
  gameId: Scalars['ID']['input'];
}>;

export type MyGameVoteDetailsQuery = {
  __typename?: 'Query';
  myGames?: Array<{
    __typename?: 'MyGames';
    participant: {
      __typename?: 'Participant';
      score: number;
      scoreReport?: {
        __typename?: 'ScoreReport';
        rounds: Array<{
          __typename?: 'RoundReport';
          correct: number;
          incorrect: number;
          round: number;
        }>;
      } | null;
    };
  }> | null;
};

export type MyGamesListVoteDetailsQueryVariables = Exact<{
  gameIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type MyGamesListVoteDetailsQuery = {
  __typename?: 'Query';
  myGames?: Array<{
    __typename?: 'MyGames';
    game: {__typename?: 'Game'; id: string};
    participant: {__typename?: 'Participant'; score: number};
  }> | null;
};

export type GameRankingsQueryVariables = Exact<{
  type: RankingType;
}>;

export type GameRankingsQuery = {
  __typename?: 'Query';
  rankings?: Array<{
    __typename?: 'Rank';
    rank: number;
    score: number;
    user: {
      __typename?: 'PublicProfile';
      firstName?: string | null;
      avatar?: string | null;
    };
  }> | null;
};

export type UpdateGameSubscriptionVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type UpdateGameSubscription = {
  __typename?: 'Subscription';
  gameUpdated: {
    __typename?: 'Game';
    currentRound: number;
    players?: Array<{
      __typename?: 'Player';
      name: string;
      avatar: string;
      isAlive: boolean;
    }> | null;
  };
};

export const RequestVerificationCodeDocument = gql`
  mutation requestVerificationCode($phoneNumber: String!) {
    requestVerificationCode(phone: $phoneNumber)
  }
`;

export function useRequestVerificationCodeMutation() {
  return Urql.useMutation<
    RequestVerificationCodeMutation,
    RequestVerificationCodeMutationVariables
  >(RequestVerificationCodeDocument);
}
export const LoginDocument = gql`
  mutation login($phoneNumber: String!, $code: String!) {
    login(phone: $phoneNumber, verificationCode: $code) {
      accessToken
      refreshToken
    }
  }
`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
export const RefreshTokenDocument = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
  );
}
export const UpdateProfileDocument = gql`
  mutation updateProfile($updateProfileData: UpdateUserInput!) {
    updateProfile(updateProfileInput: $updateProfileData) {
      id
      avatar
    }
  }
`;

export function useUpdateProfileMutation() {
  return Urql.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument);
}
export const JoinGameDocument = gql`
  mutation joinGame($id: ID!) {
    joinGame(id: $id) {
      score
    }
  }
`;

export function useJoinGameMutation() {
  return Urql.useMutation<JoinGameMutation, JoinGameMutationVariables>(
    JoinGameDocument,
  );
}
export const VoteGameDocument = gql`
  mutation voteGame($gameID: ID!, $votes: [VoteGameInput!]!) {
    voteGame(id: $gameID, votes: $votes) {
      round
    }
  }
`;

export function useVoteGameMutation() {
  return Urql.useMutation<VoteGameMutation, VoteGameMutationVariables>(
    VoteGameDocument,
  );
}
export const GamesDocument = gql`
  query games($filter: FilterGames) {
    games(filter: $filter) {
      id
      title
      subtitle
      episode
      description
      participantsCount
      banner
      players {
        name
        avatar
        role {
          name
          type
        }
      }
    }
  }
`;

export function useGamesQuery(
  options?: Omit<Urql.UseQueryArgs<GamesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GamesQuery, GamesQueryVariables>({
    query: GamesDocument,
    ...options,
  });
}
export const OnlineGameDocument = gql`
  query onlineGame($id: ID!) {
    game(id: $id) {
      id
      title
      episode
      description
      banner
      status
      streamInput
      thumbnail
      streamHlsURL
      participantsCount
      currentRound
      players {
        id
        name
        avatar
        isAlive
      }
    }
  }
`;

export function useOnlineGameQuery(
  options: Omit<Urql.UseQueryArgs<OnlineGameQueryVariables>, 'query'>,
) {
  return Urql.useQuery<OnlineGameQuery, OnlineGameQueryVariables>({
    query: OnlineGameDocument,
    ...options,
  });
}
export const ProfileDocument = gql`
  query profile {
    profile {
      firstName
      avatar
      totalScore
      monthlyScore
      remainingVotes
      totalScore
    }
  }
`;

export function useProfileQuery(
  options?: Omit<Urql.UseQueryArgs<ProfileQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ProfileQuery, ProfileQueryVariables>({
    query: ProfileDocument,
    ...options,
  });
}
export const PlayedGameDocument = gql`
  query playedGame($id: ID!) {
    game(id: $id) {
      title
      episode
      description
      banner
      status
      archiveHlsURL
      players {
        name
        avatar
        role {
          name
        }
      }
    }
  }
`;

export function usePlayedGameQuery(
  options: Omit<Urql.UseQueryArgs<PlayedGameQueryVariables>, 'query'>,
) {
  return Urql.useQuery<PlayedGameQuery, PlayedGameQueryVariables>({
    query: PlayedGameDocument,
    ...options,
  });
}
export const UserGameScoreDocument = gql`
  query userGameScore {
    profile {
      id
      weeklyScore
      remainingVotes
      votes {
        gameID
        round
      }
      participants {
        score
        remainingVotes
        gameID
      }
    }
  }
`;

export function useUserGameScoreQuery(
  options?: Omit<Urql.UseQueryArgs<UserGameScoreQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserGameScoreQuery, UserGameScoreQueryVariables>({
    query: UserGameScoreDocument,
    ...options,
  });
}
export const UserVoteRemainedDocument = gql`
  query userVoteRemained {
    profile {
      remainingVotes
    }
  }
`;

export function useUserVoteRemainedQuery(
  options?: Omit<Urql.UseQueryArgs<UserVoteRemainedQueryVariables>, 'query'>,
) {
  return Urql.useQuery<UserVoteRemainedQuery, UserVoteRemainedQueryVariables>({
    query: UserVoteRemainedDocument,
    ...options,
  });
}
export const MyGameDocument = gql`
  query myGame($filter: FilterGames, $pagination: Pagination) {
    myGames(filter: $filter, pagination: $pagination) {
      participant {
        remainingVotes
      }
    }
  }
`;

export function useMyGameQuery(
  options?: Omit<Urql.UseQueryArgs<MyGameQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MyGameQuery, MyGameQueryVariables>({
    query: MyGameDocument,
    ...options,
  });
}
export const MyGameVoteDetailsDocument = gql`
  query myGameVoteDetails($gameId: ID!) {
    myGames(filter: {ids: [$gameId]}) {
      participant {
        score
        scoreReport {
          rounds {
            correct
            incorrect
            round
          }
        }
      }
    }
  }
`;

export function useMyGameVoteDetailsQuery(
  options: Omit<Urql.UseQueryArgs<MyGameVoteDetailsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<MyGameVoteDetailsQuery, MyGameVoteDetailsQueryVariables>(
    {query: MyGameVoteDetailsDocument, ...options},
  );
}
export const MyGamesListVoteDetailsDocument = gql`
  query myGamesListVoteDetails($gameIds: [ID!]) {
    myGames(filter: {ids: $gameIds}) {
      game {
        id
      }
      participant {
        score
      }
    }
  }
`;

export function useMyGamesListVoteDetailsQuery(
  options?: Omit<
    Urql.UseQueryArgs<MyGamesListVoteDetailsQueryVariables>,
    'query'
  >,
) {
  return Urql.useQuery<
    MyGamesListVoteDetailsQuery,
    MyGamesListVoteDetailsQueryVariables
  >({query: MyGamesListVoteDetailsDocument, ...options});
}
export const GameRankingsDocument = gql`
  query gameRankings($type: RankingType!) {
    rankings(type: $type) {
      user {
        firstName
        avatar
      }
      rank
      score
    }
  }
`;

export function useGameRankingsQuery(
  options: Omit<Urql.UseQueryArgs<GameRankingsQueryVariables>, 'query'>,
) {
  return Urql.useQuery<GameRankingsQuery, GameRankingsQueryVariables>({
    query: GameRankingsDocument,
    ...options,
  });
}
export const UpdateGameDocument = gql`
  subscription updateGame($id: ID!) {
    gameUpdated(id: $id) {
      currentRound
      players {
        name
        avatar
        isAlive
      }
    }
  }
`;

export function useUpdateGameSubscription<TData = UpdateGameSubscription>(
  options: Omit<
    Urql.UseSubscriptionArgs<UpdateGameSubscriptionVariables>,
    'query'
  >,
  handler?: Urql.SubscriptionHandler<UpdateGameSubscription, TData>,
) {
  return Urql.useSubscription<
    UpdateGameSubscription,
    TData,
    UpdateGameSubscriptionVariables
  >({query: UpdateGameDocument, ...options}, handler);
}
