const label = {
  yourNameOp: 'نام شما (اختیاری)',
  phoneNumber: 'شماره موبایل',
  club: 'کلاب',
  home: 'خانه',
  profile: 'حساب من',
  orders: 'سفارش ها',
  enterPrice: 'مبلغ مورد نظر خود را وارد کنید',
  basket: 'سبد خرید',
  setting: 'تنظیمات',
  lastGame: 'آخرین بازی',
  weaklyGame: 'هفتگی',
  monthlyGame: 'ماهانه',
};

export default label;
