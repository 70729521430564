import {memo} from 'react';
import styled from '@emotion/native';
import chroma from 'chroma-js';
import {useAtom} from 'jotai';

import {playerRefAtom} from '@src/components/molocules/Player/controls.store';
import {spacing} from '@src/theme/units';

import Fullscreen from './Fullscreen';
import Live from './Live';
import PlayControl from './PlayControl';
import ProgressBar from './ProgressBar';
import Volume from './Volume';

type Props = {
  isLive?: boolean;
};

const CustomControls = memo(({isLive = false}: Props) => {
  const [playerRef] = useAtom(playerRefAtom);

  const seekToLive = () => {
    const t = playerRef?.current?.getDuration();
    t && playerRef?.current?.seekTo(t);
  };

  return (
    <CustomControlsContainer>
      <PlayControl />
      <ProgressBar isLive={isLive} />
      {isLive && <Live onPress={seekToLive} />}
      <Volume />
      <Fullscreen />
    </CustomControlsContainer>
  );
});

export default CustomControls;

const CustomControlsContainer = styled.View(({theme}) => ({
  position: 'absolute',
  zIndex: 2,
  bottom: 8,
  left: 0,
  right: 0,
  backgroundColor: chroma(theme.colors.surface).alpha(0.8) as unknown as string,
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: 52,
  width: '90%',
  marginHorizontal: 'auto',
  borderRadius: spacing(1.5),
  padding: spacing(1.5),
}));
