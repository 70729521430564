import {useCallback, useEffect} from 'react';
import {Pressable, StyleSheet} from 'react-native';
import {Snackbar, Text} from 'react-native-paper';
import styled from '@emotion/native';
import {atom, useAtom} from 'jotai';
import {noop} from 'lodash';

import CloseIcon from '@src/assets/icons/close.svg';
import {spacing, spacingPX} from '@src/theme/units';

const duration = 80000;

const SnackBarProvider = () => {
  const [{onDismiss, message, title}, setDetails] = useAtom(snackbarAtom);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDetails({message: undefined});
    }, duration);
    return () => {
      clearTimeout(timeout);
    };
  }, [message, setDetails]);

  const hideMessage = useCallback(() => {
    setDetails({message: undefined});
  }, [setDetails]);
  return (
    <CSnackbar
      wrapperStyle={style.wrapper}
      visible={(message ?? '').length > 0}
      onDismiss={onDismiss || noop}>
      <SnackBarHeader>
        <CloseBtn onPress={hideMessage}>
          <CloseIcon />
        </CloseBtn>
        <SnackBarText variant={'titleLarge'}>{title}</SnackBarText>
      </SnackBarHeader>
      <SnackBarDescription>{message}</SnackBarDescription>
    </CSnackbar>
  );
};

export default SnackBarProvider;

const style = StyleSheet.create({
  wrapper: {
    zIndex: 99999,
    top: 70,
    borderRadius: 45,
    width: '95%',
    margin: 'auto',
    alignSelf: 'center',
    maxWidth: 900,
  },
  snackbar: {
    backgroundColor: '',
    textAlign: 'right',
  },
});

const SnackBarText = styled(Text)`
  text-align: right;
  color: ${p => p.theme.colors.primary};
  padding-right: ${spacingPX(0.5)};
  padding-top: ${spacingPX(0.5)};
  margin-top: ${spacingPX(1)};
  font-weight: 500;
`;
const CSnackbar = styled(Snackbar)`
  background-color: ${p => p.theme.colors.outline};
  color: ${p => p.theme.colors.primary};
  flex-direction: row;
  border-radius: ${p => p.theme.roundness * 4 + 'px'};
`;

const SnackBarHeader = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const SnackBarDescription = styled(Text)(props => ({
  textAlign: 'right',
  marginVertical: spacing(1),
  color: props.theme.colors.onBackground,
}));

const CloseBtn = styled(Pressable)({});

type SnackbarType = {
  title?: string;
  message?: string;
  onDismiss?: () => void;
};

export const snackbarAtom = atom<SnackbarType>({});
