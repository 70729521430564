const buttons = {
  sendCode: 'ارسال کد',
  moreInfo: 'اطلاعات بیشتر',
  comment: 'نظر',
  add: 'افزودن',
  loginWithNumber: 'ورود با شماره موبایل',
  goAhead: 'ادامه بده',
  understand: 'فهمیدم',
  save: 'ذخیره',
  nevermind: 'بی خیال',
  exit: 'خروج',
  addNewAddress: 'افزودن آدرس جدید',
  editAddress: 'ویرایش',
  deleteAddress: 'حذف آدرس',
  yes: 'اره',
  showInvoice: 'مشاهده فاکتور',
  reOrder: 'سفارش مجدد',
  allCategory: 'همه‌ دسته ‌ها',
  filters: 'فیلترها',
  inPerson: 'مراجعه حضوری',
  pay: 'پرداخت آنلاین',
  confirmCode: 'اعمال کد تخفیف',
  reset: 'ریست کردن',
  submitFilter: 'اعمال فیلترها',
  confirm: 'تایید',
  editPosition: 'ویرایش موقعیت',
  CompletePurchase: 'تکمیل خرید',
  iGetIt: 'متوجه شدم!',
  install: 'نصب',
  cancel: 'انصراف',
  chargeWallet: 'شارژ کیف پول',
  continueShopping: 'ادامه خرید',
  deleteBasket: 'حذف سبد',
  seeAllRestaurant: 'مشاهده همه رستوران‌ها',
  goHomePage: 'رفتن به صفحه اصلی',
  enterGame: 'ورود به بازی',
  playGame: 'پخش بازی',
  onlineWatch: 'تماشای آنلاین',
  shotNow: 'همین حالا مافیا رو بزن!',
};

export default buttons;
