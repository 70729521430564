import {Platform} from 'react-native';

export const appName = 'Mafia';

export const phoneNumberRegex = '^09[0-9]{9}$';
export const graphqlURL =
  'https://web-mafia-dev.apps.ir-thr-ba1.arvanpaas.ir/query';

export const sentryDsn =
  Platform.OS === 'web'
    ? 'https://9ef065788a0a420a92fce75ea2ea34fd@sentry.mallato.ir/8'
    : 'https://19a0ca237d03406dae16e1b59fa96303@sentry.mallato.ir/9';

export const sentryTracesSampleRate = 0.1;
