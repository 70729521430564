import {Image} from 'react-native';
import styled from '@emotion/native';

import BackButton from '@src/components/atoms/BackButton';
import type {Game} from '@src/data/generated';

type Props = {
  game?: Game;
};

const Header = ({game}: Props) => (
  <>
    <CustomBackBtn />
    <HeaderImage
      source={
        game?.banner ? {uri: game?.banner} : require('../mock/headerImage.jpg')
      }
    />
  </>
);

export default Header;

const HeaderImage = styled(Image)({width: '100%', height: 200});

const CustomBackBtn = styled(BackButton)({
  zIndex: 1,
});
