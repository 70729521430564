import {useCallback} from 'react';
import {FlatList, ScrollView} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import {get} from 'lodash';

import CupIcon from '@src/assets/icons/cup.svg';
import Container from '@src/components/atoms/Container';
import IconButton from '@src/components/atoms/IconButton';
import Loading from '@src/components/atoms/Loading';
import StatusBar from '@src/components/atoms/StatusBar';
import FutureGameCard from '@src/components/screens/Home/FutureGameCard';
import PreviousGameCard from '@src/components/screens/Home/PreviousGameCard';
import User from '@src/components/screens/Home/User';
import type {Game} from '@src/data/generated';
import {
  GameStatus,
  useGamesQuery,
  useMyGameQuery,
  useMyGamesListVoteDetailsQuery,
  useProfileQuery,
} from '@src/data/generated';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import OnlineGameCard from './OnlineGameCard';
import UserDetails from './UserDetails';
import UserScores from './UserScores';

type ScreenNavigationProp = StackNavigationProp<RootStackParamList, 'Main'>;

const Home = () => {
  const [{data: profileData}] = useProfileQuery();

  const [{data: finishedGame, fetching}] = useGamesQuery({
    variables: {filter: {status: GameStatus.Finished}},
  });

  const [{data: ScoresData}] = useMyGamesListVoteDetailsQuery({
    variables: {gameIds: finishedGame?.games?.map(game => game.id) ?? []},
  });

  const [{data: myGamesData}] = useMyGameQuery({
    variables: {pagination: {sort: '-createdAt'}},
  });

  const [{data: inProgressGames}] = useGamesQuery({
    variables: {filter: {statusIn: [GameStatus.InProgress, GameStatus.Voting]}},
  });

  const [{data: createdGames}] = useGamesQuery({
    variables: {filter: {statusIn: [GameStatus.Created, GameStatus.Preparing]}},
  });

  const {navigate} = useNavigation<ScreenNavigationProp>();

  const handleRedirectToRank = useCallback(() => {
    navigate('Rank');
  }, [navigate]);
  // const {} = useGamesQuery();

  if (fetching) {
    return <Loading />;
  }
  return (
    <SafeArea>
      <StatusBar />
      <ScrollView>
        <CustomContainer disablePadding>
          <TopCard>
            <CustomButton icon={CupIcon} onPress={handleRedirectToRank} />
            <User
              name={profileData?.profile.firstName ?? ''}
              avatar={profileData?.profile.avatar ?? ''}
            />
          </TopCard>
          <InformationCard>
            <UserScores
              totalScore={profileData?.profile.totalScore}
              monthlyScore={profileData?.profile.monthlyScore}
            />
            <UserDetails
              totalScore={profileData?.profile.totalScore}
              remainingVotes={get(
                myGamesData,
                'myGames[0].participant.remainingVotes',
                0,
              )}
            />
          </InformationCard>
          {inProgressGames?.games && inProgressGames?.games?.length > 0 && (
            <OnlineGameCard data={get(inProgressGames, 'games.[0]', null)} />
          )}
          {finishedGame &&
          finishedGame?.games &&
          finishedGame?.games?.length > 0 ? (
            <GameArea>
              <Title variant="headlineSmall">
                {translations.common.previousMatches}
              </Title>
              <FlatList
                snapToAlignment="start"
                nestedScrollEnabled={true}
                inverted
                showsHorizontalScrollIndicator={false}
                horizontal
                data={finishedGame?.games.sort(
                  // @ts-ignore
                  (a, b) => b?.episode! - a?.episode!,
                )}
                keyExtractor={item => item.id}
                renderItem={({item}) => (
                  <PreviousGameCard
                    data={item as Game}
                    score={
                      ScoresData?.myGames?.find(e => e.game.id === item.id)
                        ?.participant?.score
                    }
                  />
                )}
              />
            </GameArea>
          ) : null}
          {createdGames?.games && createdGames?.games?.length > 0 && (
            <GameArea>
              <Title variant="headlineSmall">
                {translations.common.comingSoon}
              </Title>
              <FlatList
                snapToAlignment="start"
                nestedScrollEnabled={true}
                inverted
                showsHorizontalScrollIndicator={false}
                horizontal
                data={createdGames?.games.sort(
                  // @ts-ignore
                  (a, b) => a?.episode! - b?.episode!,
                )}
                keyExtractor={item => item.id}
                renderItem={item => <FutureGameCard data={item} />}
              />
            </GameArea>
          )}
        </CustomContainer>
      </ScrollView>
    </SafeArea>
  );
};

export default Home;

const SafeArea = styled.SafeAreaView`
  flex: 1;
`;

// @ts-ignore
const CustomContainer = styled(Container)({
  alignItems: 'right',
  justifyContent: 'right',
  marginTop: spacing(2),
  marginBottom: spacing(4),
});

// @ts-ignore
const CustomButton = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.colors.surface,
  borderRadius: '50%',
  width: 56,
  // eslint-disable-next-line max-lines
  height: 56,
}));

const TopCard = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingHorizontal: spacing(2),
});

const InformationCard = styled.View({
  flexDirection: 'row',

  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: spacing(3),
  paddingHorizontal: spacing(2),
});

const GameArea = styled.View({
  marginTop: spacing(3),
  paddingRight: spacing(2),
});

const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  textAlign: 'right',
}));
