// description will be change after give me real text and maybe move to translation common ?
export const dataMock = [
  {
    key: 1,
    description:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با چاپگرها و متون لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با چاپگرها و متون بلکه روزنامه استفاده از طراحان گرافیک است',
    image: require('./assets/Slide1.png'),
  },
  {
    key: 2,
    description:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با چاپگرها و متون لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با چاپگرها و متون بلکه روزنامه استفاده از طراحان گرافیک است',
    image: require('./assets/Slide2.png'),
  },
  {
    key: 3,
    description:
      'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با چاپگرها و متون لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با چاپگرها و متون بلکه روزنامه استفاده از طراحان گرافیک است',
    image: require('./assets/Slide.png'),
  },
];
