import type {Dispatch} from 'react';
import {memo, useCallback} from 'react';
import type {ButtonProps} from 'react-native-paper';
import {Button} from 'react-native-paper';
import styled, {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {useTheme} from '@emotion/react';

import {spacingPX} from '@src/theme/units';

type Props = {
  onValueChange: Dispatch<string>;
  value: string;
  buttons: {label: string; value: string}[];
};

const ButtonGroup = ({buttons, onValueChange, value}: Props) => {
  const theme = useTheme();
  const handleChange = useCallback(
    (nValue: string) => {
      onValueChange(nValue);
    },
    [onValueChange],
  );

  return (
    <Row>
      <CButton
        theme={theme}
        active={value === buttons[0].value}
        labelStyle={labelStyle(theme, value === buttons[0].value)}
        onPress={() => handleChange(buttons[0].value)}>
        {buttons[0].label}
      </CButton>
      <CButton
        labelStyle={labelStyle(theme, value === buttons[1].value)}
        theme={theme}
        active={value === buttons[1].value}
        onPress={() => handleChange(buttons[1].value)}>
        {buttons[1].label}
      </CButton>
      <CButton
        labelStyle={labelStyle(theme, value === buttons[2].value)}
        theme={theme}
        active={value === buttons[2].value}
        onPress={() => handleChange(buttons[2].value)}>
        {buttons[2].label}
      </CButton>
    </Row>
  );
};
export default memo(ButtonGroup);

const CButton = styled(Button)<ButtonProps & {active: boolean}>`
  background-color: ${p =>
    p.active ? p.theme.colors.primary : p.theme.colors.surface};
  border-radius: 0;
  width: 34%;
`;
const Row = styled.View`
  flex-direction: row-reverse;
  width: 100%;
  border-radius: ${p => p.theme.roundness * 5 + 'px'};
  overflow: hidden;
  border: 0px solid ${p => p.theme.colors.outline};
`;
const labelStyle = (theme: Theme, active: boolean) => css`
  color: ${active ? theme.colors.background : theme.colors.onSurface};
  padding: ${spacingPX(0.5)};
`;
