import {atom} from 'jotai';
import {atomWithStorage} from 'jotai/utils';

export const refreshTokenAtom = atomWithStorage<string | undefined>(
  'refresh_token',
  undefined,
);
export const accessTokenAtom = atomWithStorage<string | undefined>(
  'accessTokenAtom',
  undefined,
);
export const profileAtom = atom<any | undefined>(undefined);

let accessToken: string | undefined;
export const getAccessToken = (): string | undefined => accessToken;
export const storeAccessToken = (at?: string): void => {
  accessToken = at;
};

export const usernameAtom = atomWithStorage('username', '');
export const otpAtom = atomWithStorage('otp', false);
