import {memo} from 'react';
import type {IconButtonProps} from 'react-native-paper';
import styled from '@emotion/native';

import Icon from '@src/assets/icons/arrow-left.svg';
import IconButton from '@src/components/atoms/IconButton';
import {useGoBack} from '@src/hooks/useGoBack';
import {spacing} from '@src/theme/units';

type Props = Omit<IconButtonProps, 'icon'>;

const BackButton = ({style, onPress}: Props) => {
  const {goBack} = useGoBack();

  return (
    <CustomButton
      onPress={e => {
        goBack();
        onPress && onPress(e);
      }}
      mode="contained"
      style={style}
      icon={Icon}
    />
  );
};
export default memo(BackButton);

// @ts-ignore
const CustomButton = styled(IconButton)(({theme}) => ({
  position: 'absolute',
  top: spacing(1),
  left: spacing(1),
  backgroundColor: theme.colors.surface,
  borderRadius: '50%',
  width: 56,
  height: 56,
}));
