import {Image, Pressable} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native/dist/emotion-native.cjs';

import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type BulletItem = {
  item: {
    image: number; // Assuming 'require' returns a number for the image
    entity: number;
    title: string;
    desc: string;
    price: number;
    discount: number;
    premium: boolean;
  };
};

const ItemCard = ({item}: BulletItem) => (
  <ItemsCard isPremium={item.premium}>
    {item.premium && (
      <OfferLabel>
        <OfferText>{translations.common.specialOffer}</OfferText>
      </OfferLabel>
    )}
    <BulletImage
      source={item.image}
      resizeMode={'center'}
      resizeMethod={'auto'}
    />
    <BulletEntityContainer>
      <BulletEntityText isPremium={item.premium}>تیر</BulletEntityText>
      <BulletEntity isPremium={item.premium}>
        {item.entity.toLocaleString('fa')}
      </BulletEntity>
    </BulletEntityContainer>
    <Title variant="labelMedium">{item.title}</Title>
    <Description>{item.desc}</Description>
    <Amount>:{translations.common.amountPayable}</Amount>
    <PriceContainer>
      <TomanText variant="labelMedium"> {translations.common.toman}</TomanText>
      <PriceNumber variant="labelMedium">
        {item.price.toLocaleString('fa')}
      </PriceNumber>
    </PriceContainer>
    <DiscountContainer>
      <TomanWhiteText variant="bodyLarge" isPremium={item.premium}>
        {translations.common.toman}
      </TomanWhiteText>
      <DiscountNumber variant="bodyLarge" isPremium={item.premium}>
        {item.discount.toLocaleString('fa')}
      </DiscountNumber>
    </DiscountContainer>
  </ItemsCard>
);

export default ItemCard;

const ItemsCard = styled(Pressable)(({theme, isPremium}: any) => ({
  alignItems: 'center',
  // width: 100,
  backgroundColor: theme.colors.outline,
  borderRadius: theme.roundness * 6,
  paddingHorizontal: spacing(1.5),
  paddingVertical: spacing(1.5),
  borderWidth: isPremium ? 1 : 0,
  borderColor: isPremium && theme.colors.primary,
}));

const BulletImage = styled(Image)({
  width: 90,
  height: 50,
});

const BulletEntityContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
const BulletEntity = styled(Text)<{isPremium: boolean}>(props => ({
  fontSize: 20,
  color: props.isPremium
    ? props.theme.colors.primary
    : props.theme.colors.onBackground,
  textAlign: 'right',
}));

const BulletEntityText = styled(Text)<{isPremium: boolean}>(props => ({
  color: props.isPremium
    ? props.theme.colors.primary
    : props.theme.colors.onBackground,
  textAlign: 'right',
  marginRight: spacing(0.4),
}));

const Title = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));

const Description = styled(Text)(props => ({
  color: props.theme.colors.onSurfaceDisabled,
  fontSize: 9,
  textAlign: 'center',
  width: 90,
}));

const Amount = styled(Text)(props => ({
  color: props.theme.colors.onSurfaceDisabled,
  fontSize: 9,
  textAlign: 'center',
}));

const PriceContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

const PriceNumber = styled(Text)(props => ({
  color: props.theme.colors.secondary,
  textDecorationLine: 'line-through',
}));

const TomanText = styled(Text)(props => ({
  color: props.theme.colors.secondary,
  marginRight: spacing(0.3),
}));

const DiscountContainer = styled.View({
  flexDirection: 'row',
  marginTop: spacing(0.2),
});

const DiscountNumber = styled(Text)<{isPremium: boolean}>(props => ({
  color: props.isPremium
    ? props.theme.colors.primary
    : props.theme.colors.onBackground,
}));

const TomanWhiteText = styled(Text)<{isPremium: boolean}>(props => ({
  color: props.isPremium
    ? props.theme.colors.primary
    : props.theme.colors.onBackground,
  marginRight: spacing(0.3),
}));

const OfferLabel = styled.View(props => ({
  backgroundColor: props.theme.colors.primary,
  position: 'absolute',
  zIndex: 2,
  borderRadius: props.theme.roundness * 2,
  // width: 50,
  alignItems: 'center',
  justifyContent: 'center',
  padding: spacing(0.4),
  top: -10,
}));
const OfferText = styled(Text)(props => ({
  color: props.theme.colors.onPrimary,
  fontSize: 8,
  fontWeight: '800',
}));
