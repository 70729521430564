import {memo, useCallback, useEffect, useRef} from 'react';
import {css} from '@emotion/native';
import {useTheme} from '@emotion/react';
import OTPInputView from '@twotalltotems/react-native-otp-input';

import type {Theme} from '@src/theme/types';
import {spacing} from '@src/theme/units';

type Props = {
  disabled?: boolean;
  code?: string;
  onCodeChanged?: (code: string) => void;
  onCodeFilled?: (code: string) => void;
};

const OTPInput = ({disabled, code, onCodeFilled, onCodeChanged}: Props) => {
  const ref = useRef<any>(null);
  const theme = useTheme();
  const handleCodeChange = useCallback(
    (newCode: string) => {
      if (/^\d+$/.test(newCode)) {
        onCodeChanged?.(newCode);
      } else {
        onCodeChanged?.('');
      }
    },
    [onCodeChanged],
  );

  useEffect(() => {
    ref.current?.fields[0]?.focus();
  }, [ref]);

  return (
    <OTPInputView
      ref={ref}
      editable={disabled}
      keyboardAppearance={theme.dark ? 'dark' : 'light'}
      pinCount={4}
      code={code}
      onCodeChanged={handleCodeChange}
      autoFocusOnLoad
      codeInputFieldStyle={codeInputFieldStyle(theme)}
      codeInputHighlightStyle={codeInputHighlightStyle(theme)}
      selectionColor={theme.colors.secondary}
      onCodeFilled={onCodeFilled}
    />
  );
};

export default memo(OTPInput);

const codeInputFieldStyle = (theme: Theme) =>
  css({
    borderWidth: 2,
    borderColor: theme.colors.surface,
    backgroundColor: theme.colors.surface,
    borderRadius: spacing(theme.roundness),
    marginHorizontal: spacing(0.75),
    width: 67,
    height: 67,
    color: theme.colors.primary,
    fontSize: 24,
  });

const codeInputHighlightStyle = (theme: Theme) =>
  css({
    borderWidth: 2,
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
  });
