const screens = {
  login: 'ورود',
  otp: 'کد تایید ورود',
  introduction: 'معرفی',
  pwa: 'نسخه وب آپلیکشن (PWA)',
  SignUp: 'ثبت نام',
  Main: 'صفحه اصلی',
};

export default screens;
