import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import BackButton from '@src/components/atoms/BackButton';
import translations from '@src/translations';

const Header = () => (
  <Container>
    <CustomBackBtn />
    <Title variant={'titleLarge'}>{translations.common.setting}</Title>
  </Container>
);

export default Header;

const Container = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

const CustomBackBtn = styled(BackButton)({
  position: 'relative',
  top: 0,
  left: 0,
  margin: 0,
});

const Title = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));
