import {SheetProvider} from 'react-native-actions-sheet';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import {ActivityIndicator} from 'react-native-paper';
import {css} from '@emotion/native';
import type {LinkingOptions} from '@react-navigation/native';
import {NavigationContainer} from '@react-navigation/native';

import SnackBarProvider from '@src/components/providers/SnackbarProvider';
import QueryClientProvider from '@src/data/QueryClientProvider';
import useHandleReady from '@src/hooks/useHandleReady';
import {navigationLightTheme} from '@src/theme/theme';

import {navigationRef} from './RootNavigation';
import type {RootStackParamList} from './RootStack';
import RootStack from './RootStack';

const Navigation = () => {
  // install react-navigation in flipper
  const handleReady = useHandleReady();

  return (
    <GestureHandlerRootView style={baseStyle}>
      <NavigationContainer<RootStackParamList>
        ref={navigationRef}
        onReady={handleReady}
        linking={linking}
        theme={navigationLightTheme}
        fallback={<ActivityIndicator animating />}>
        <QueryClientProvider>
          <SheetProvider>
            <SnackBarProvider />
            <RootStack />
          </SheetProvider>
        </QueryClientProvider>
      </NavigationContainer>
    </GestureHandlerRootView>
  );
};

export default Navigation;

const baseStyle = css`
  flex: 1;
`;

export const websitePrefix = 'https://Mafia.com';
export const inAppPrefix = 'Mafia://';

const linking: LinkingOptions<RootStackParamList> = {
  enabled: true,
  prefixes: [websitePrefix, inAppPrefix],
};
