import styled from '@emotion/native/dist/emotion-native.cjs';

import LiveIcon from '@src/assets/icons/live.svg';
import {spacing} from '@src/theme/units';

type Props = {
  onPress?: () => void;
};

const Live = ({onPress}: Props) => (
  <IconContainer onPress={onPress}>
    <LiveIcon />
  </IconContainer>
);

export default Live;

const IconContainer = styled.Pressable({
  marginLeft: spacing(1.5),
});
