import styled from '@emotion/native';
import {useAtom} from 'jotai';

import PlayIcon from '@src/assets/icons/play.svg';
import {isPlayingAtom} from '@src/components/molocules/Player/controls.store';

type Props = {
  uri?: string;
};

const Thumbnail = ({uri}: Props) => {
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom);

  return (
    <ThumbnailContainer>
      <CThumbnail
        source={{
          uri,
        }}
      />
      <PlayIconContainer onPress={() => setIsPlaying(!isPlaying)}>
        <PlayIcon />
      </PlayIconContainer>
    </ThumbnailContainer>
  );
};
export default Thumbnail;

const ThumbnailContainer = styled.View({
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
});
const CThumbnail = styled.Image({
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 0,
});
const PlayIconContainer = styled.Pressable({
  position: 'relative',
  bottom: 20,
});
