import {memo} from 'react';
import styled from '@emotion/native';

type ContainerProps = {
  disablePadding?: boolean;
};

const Container = styled.View<ContainerProps>(props => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  padding: props.disablePadding ? 0 : '6%',
  backgroundColor: props.theme.colors.background,
}));

export default memo(Container);
