import type {ForwardedRef} from 'react';
import {forwardRef, memo} from 'react';
import type {ButtonProps} from 'react-native-paper';
import {Button as BT} from 'react-native-paper';
import {css} from '@emotion/native';

import {spacing} from '@src/theme/units';

const Button = forwardRef(
  (props: Omit<ButtonProps, 'theme'>, ref: ForwardedRef<any>) => {
    const {contentStyle, style, ...newProps} = props;
    return (
      <BT
        ref={ref}
        {...newProps}
        style={[buttonStyle, style]}
        contentStyle={[buttonContentStyle, contentStyle]}
      />
    );
  },
);
export default memo(Button);

const buttonStyle = css({
  width: '100%',
  overflow: 'hidden',
  height: spacing(7),
});
const buttonContentStyle = css({
  width: '100%',
  height: spacing(7),
  alignItems: 'center',
  justifyContent: 'center',
});
