import {memo} from 'react';
import {Pressable} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import translations from '@src/translations';

type Props = {
  onResend: () => void;
};

const Resend = ({onResend}: Props) => (
  <Container>
    <TBody variant="bodyMedium">{translations.common.didntGetCode}</TBody>
    <Pressable onPress={onResend}>
      <TLink>{translations.common.resendCode}</TLink>
    </Pressable>
  </Container>
);

export default memo(Resend);

const TBody = styled(Text)`
  margin-top: ${props => props.theme.spacing(4) + 'px'};
  color: ${props => props.theme.colors.onSurfaceDisabled};
`;

const Container = styled.View`
  width: 100%;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
`;

const TLink = styled(Text)`
  margin-top: ${props => props.theme.spacing(4) + 'px'};
  margin-right: ${props => props.theme.spacing(1) + 'px'};
  color: ${props => props.theme.colors.primary};
`;
