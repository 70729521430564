import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import AddIcon from '@src/assets/icons/addbullet.svg';
import BulletIcon from '@src/assets/icons/largeBullet.svg';
import TrophyIcon from '@src/assets/icons/trophy.svg';
import BackButton from '@src/components/atoms/BackButton';
import {spacing} from '@src/theme/units';

type Props = {
  score?: number;
  remainedVotes?: number;
};

const Header = ({score, remainedVotes}: Props) => (
  <Container>
    <BackBt />
    <TrophyContainer>
      <TrophyIcon />
      <RateText>{score?.toLocaleString('fa')}</RateText>
    </TrophyContainer>
    <BulletContainer>
      <BulletIconContainer>
        <BulletIcon />
      </BulletIconContainer>
      <BulletText>{remainedVotes?.toLocaleString('fa')}</BulletText>
      <AddIcon />
    </BulletContainer>
  </Container>
);

export default Header;

const Container = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: spacing(3),
});

const BulletContainer = styled.View(props => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 120,
  height: 50,
  backgroundColor: props.theme.colors.surface,
  padding: spacing(1.5),
  borderRadius: props.theme.roundness * 6,
}));

const BulletIconContainer = styled.View({marginBottom: spacing(0.5)});

const BulletText = styled(Text)(props => ({
  fontSize: 24,
  color: props.theme.colors.onBackground,
}));

const TrophyContainer = styled.View(props => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: props.theme.colors.surface,
  width: 100,
  height: 50,
  padding: spacing(1.5),
  borderRadius: props.theme.roundness * 6,
  alignContent: 'center',
}));

const RateText = styled(Text)(props => ({
  fontSize: 24,
  color: props.theme.colors.onBackground,
}));

const BackBt = styled(BackButton)({
  position: 'relative',
  left: 0,
  top: 0,
  margin: 0,
});
