import {useCallback} from 'react';
import {Image, View} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import chroma from 'chroma-js';
import {get} from 'lodash';

import EyeIcon from '@src/assets/icons/eye.svg';
import Button from '@src/components/atoms/Button';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type ScreenProps = StackNavigationProp<RootStackParamList>;
// TODO: fix type
const OnlineGameCard = ({data}: any) => {
  const {navigate} = useNavigation<ScreenProps>();

  const handleRedirect = useCallback(() => {
    navigate('Live', {id: get(data, 'id', '')});
  }, [data, navigate]);

  return (
    <Card>
      <CImage source={{uri: get(data, 'banner', null)}} />
      <Chip>
        <EyeIcon />
        <ChipText variant="labelMedium">
          {`${Number(get(data, 'participantsCount', '')).toLocaleString('fa')}`}
        </ChipText>
        <ChipText variant="labelMedium"> {translations.common.person}</ChipText>
      </Chip>
      <LiveChip>
        <ChipText variant="labelMedium">{translations.common.live}</ChipText>
      </LiveChip>
      <Content>
        <TilteContainer>
          <EpisodeNumber variant="headlineSmall">
            {digitsEnToFa(data?.episode)}
          </EpisodeNumber>
          <EpisodeText variant="headlineSmall">
            {' '}
            {translations.common.episode}
          </EpisodeText>
          <UnderLineText variant="headlineSmall">-</UnderLineText>
          <Title variant="headlineSmall">{data?.title}</Title>
        </TilteContainer>
        <Description variant="titleSmall">{data?.subtitle}</Description>
        <CButton mode="contained" onPress={handleRedirect}>
          <ButtonText variant="titleMedium">
            {translations.buttons.onlineWatch}
          </ButtonText>
        </CButton>
      </Content>
    </Card>
  );
};

export default OnlineGameCard;

const Card = styled.View(({theme}) => ({
  borderRadius: spacing(1.5),
  overflow: 'hidden',
  marginTop: spacing(2),
  backgroundColor: theme.colors.surface,
  position: 'relative',
  marginHorizontal: spacing(2),
}));

const Content = styled.View({
  padding: spacing(2),
});

const TilteContainer = styled(View)({
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
});
const EpisodeText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));

const EpisodeNumber = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));

const UnderLineText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));

const CImage = styled(Image)({width: '100%', height: 180});

const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  textAlign: 'right',
  fontWeight: 'bold',
}));

const Description = styled(Text)(({theme}) => ({
  color: theme.colors.onSurface,
  textAlign: 'right',
}));

const ButtonText = styled(Text)(({theme}) => ({
  color: theme.colors.background,
}));

const CButton = styled(Button)({
  marginTop: spacing(2),
});

const Chip = styled.View(({theme}) => ({
  backgroundColor: chroma(theme.colors.surface).alpha(0.8) as unknown as string,
  position: 'absolute',
  top: spacing(1.5),
  left: spacing(8),
  flexDirection: 'row-reverse',
  padding: spacing(1),
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
}));
const LiveChip = styled.View(({theme}) => ({
  backgroundColor: theme.colors.secondary,
  position: 'absolute',
  top: spacing(1.5),
  left: spacing(1.5),
  flexDirection: 'row-reverse',
  padding: spacing(1),
  paddingHorizontal: spacing(1.5),
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
}));

const ChipText = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
}));
