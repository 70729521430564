import type {ReactNode} from 'react';
import {useEffect} from 'react';
import {useAtomValue} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import {accessTokenAtom, refreshTokenAtom} from '@src/auth/store';
import {useRefreshTokenMutation} from '@src/data/generated';
import useLogout from '@src/hooks/useLogout';

type Props = {
  children: ReactNode;
};

const Auth = ({children}: Props) => {
  const refreshToken = useAtomValue(refreshTokenAtom);
  const setAccessToken = useUpdateAtom(accessTokenAtom);
  const logout = useLogout();
  const [, refreshTokenMutation] = useRefreshTokenMutation();
  useEffect(() => {
    const requestRefreshToken = async () => {
      if (refreshToken) {
        try {
          const response = await refreshTokenMutation({refreshToken});
          // @ts-ignore
          if (response.data !== null && response.data !== undefined) {
            setAccessToken(response.data.refreshToken.accessToken);
          }
        } catch (e) {
          await logout();
        }
      }
    };
    requestRefreshToken();
  }, [logout, refreshToken, setAccessToken, refreshTokenMutation]);

  return <>{children}</>;
};

export default Auth;
