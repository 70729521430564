import {memo, useCallback} from 'react';
import {ScrollView} from 'react-native';
import type {NativeStackScreenProps} from 'react-native-screens/native-stack';
import styled from '@emotion/native';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';

import Button from '@src/components/atoms/Button';
import Loading from '@src/components/atoms/Loading';
import type {Game} from '@src/data/generated';
import {useJoinGameMutation, useOnlineGameQuery} from '@src/data/generated';
import useSnackbar from '@src/hooks/useSnackbar';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import Header from './Header';
import Main from './Main';

type ScreenProp = StackNavigationProp<RootStackParamList, 'Live'>;
type Props = NativeStackScreenProps<RootStackParamList, 'Live'>;

const LiveScreen = ({route: {params}}: Props) => {
  const {navigate} = useNavigation<ScreenProp>();
  const {updateSnackbar} = useSnackbar();
  const gameID = params.id;
  const [, setJoinGame] = useJoinGameMutation();
  const [{data, fetching}] = useOnlineGameQuery({
    variables: {id: gameID},
  });
  const handlePress = useCallback(async () => {
    try {
      if (
        data?.game.status === 'in_progress' ||
        data?.game.status === 'voting'
      ) {
        await setJoinGame({id: gameID});
        navigate('GameScreen', {
          gameID: gameID,
        });
      } else if (data?.game.status === 'finished') {
        updateSnackbar(
          translations.errors.error,
          translations.errors.gameFinished,
        );
      } else if (data?.game.status === 'created') {
        updateSnackbar(
          translations.errors.error,
          translations.errors.gameNotStarted,
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [data?.game.status, gameID, navigate, setJoinGame, updateSnackbar]);

  return (
    <>
      {fetching ? (
        <Loading />
      ) : (
        <>
          <Container>
            <Header game={data?.game as Game} />
            <Main game={data?.game as Game} />
          </Container>
          <ButtonContainer>
            <CustomBtn mode="contained" onPress={handlePress}>
              {translations.buttons.playGame}
            </CustomBtn>
          </ButtonContainer>
        </>
      )}
    </>
  );
};

export default memo(LiveScreen);

const Container = styled(ScrollView)({
  height: '100%',
  width: '100%',
  flex: 1,
  position: 'relative',
});
const ButtonContainer = styled.View(props => ({
  width: '100%',
  backgroundColor: props.theme.colors.background,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  height: 90,
  padding: spacing(2),
}));

const CustomBtn = styled(Button)({});
