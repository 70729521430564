import {useCallback, useEffect, useState} from 'react';
import {Image, Pressable} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';

import CheckIcon from '@src/assets/icons/avatarCheck.svg';
import BackButton from '@src/components/atoms/BackButton';
import Button from '@src/components/atoms/Button';
import Container from '@src/components/atoms/Container';
import Loading from '@src/components/atoms/Loading';
import StatusBar from '@src/components/atoms/StatusBar';
import TextInput from '@src/components/atoms/TextInput';
import {useProfileQuery, useUpdateProfileMutation} from '@src/data/generated';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type SignUpScreenProp = StackNavigationProp<RootStackParamList, 'SignUp'>;

const SignUpScreen = () => {
  const navigation = useNavigation<SignUpScreenProp>();
  const [{data, fetching}] = useProfileQuery();
  const [, setUserUpdate] = useUpdateProfileMutation();
  const [avatarID, setAvatarID] = useState<string | null>(null);
  const [name, setName] = useState<string>('');

  const handlePress = useCallback((id: string) => {
    setAvatarID(id);
  }, []);

  const handleSubmit = useCallback(async () => {
    const result = await setUserUpdate({
      updateProfileData: {avatar: avatarID, firstName: name},
    });
    if (!result.error) {
      navigation.navigate('Main');
    }
  }, [avatarID, name, navigation, setUserUpdate]);

  useEffect(() => {
    if (!avatarID || !name) {
      setAvatarID(data?.profile?.avatar ?? null);
      setName(data?.profile?.firstName ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (fetching) {
    return <Loading />;
  }
  return (
    <SafeArea>
      <StatusBar />
      <CustomContainer>
        <BackButton />
        <Title variant={'titleLarge'}>{translations.common.choiceAvatar}</Title>
        <AvatarContainer>
          {Array(9)
            .fill(null)
            .map((_, index) => (
              <AvatarPressable
                key={index}
                onPress={() => handlePress(`${index + 1}`)}>
                <CImage
                  source={require(`@src/assets/avatars/${index + 1}.png`)}
                />
                {Number(avatarID) === index + 1 && (
                  <IconContainer>
                    <CheckIcon />
                  </IconContainer>
                )}
              </AvatarPressable>
            ))}
        </AvatarContainer>
        <CTextInput
          value={name}
          onChangeText={setName}
          placeholder="نام و نام خانوادگی"
        />
        <CButton onPress={handleSubmit} mode="contained">
          <ButtonText>{translations.buttons.confirm}</ButtonText>
        </CButton>
      </CustomContainer>
    </SafeArea>
  );
};
export default SignUpScreen;

const SafeArea = styled.SafeAreaView`
  flex: 1;
`;

// @ts-ignore
const CustomContainer = styled(Container)({
  alignItems: 'right',
  justifyContent: 'right',
  marginTop: spacing(2),
});

const Title = styled(Text)(({theme}) => ({
  fontWeight: 'bold',
  color: theme.colors.onBackground,
  textAlign: 'right',
  marginTop: spacing(0.5),
}));

const AvatarContainer = styled.View({
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: spacing(3),
});

const CImage = styled(Image)(({theme}) => ({
  borderRadius: 50,
  width: 90,
  height: 90,
  backgroundColor: theme.colors.surface,
  marginTop: spacing(3),
}));

const CTextInput = styled(TextInput)({
  marginTop: spacing(5),
});
const CButton = styled(Button)({
  marginTop: spacing(3),
});

// @ts-ignore
const ButtonText = styled(Text)(({theme}) => ({
  fontWeight: 600,
  color: theme.colors.background,
}));

const AvatarPressable = styled(Pressable)({position: 'relative'});

const IconContainer = styled.View({
  position: 'absolute',
  bottom: -12,
  left: 0,
  right: 0,
  marginHorizontal: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
});
