import type {View} from 'react-native';
import styled from '@emotion/native/dist/emotion-native.cjs';
import {useAtom, useAtomValue} from 'jotai';

import FullscreenIcon from '@src/assets/icons/fullscreen.svg';
import {
  containerRefAtom,
  fullscreenAtom,
} from '@src/components/molocules/Player/controls.store';
import {spacing} from '@src/theme/units';

const Fullscreen = () => {
  const containerRef = useAtomValue(containerRefAtom);
  const [fullscreen, setFullscreen] = useAtom(fullscreenAtom);

  const handleEnterFullScreen = (element: View | null) => {
    if (element === null) {
      return;
    } else {
      // @ts-ignore
      if (element.requestFullscreen) {
        // @ts-ignore
        element.requestFullscreen();
        // @ts-ignore
      } else if (element.webkitRequestFullscreen) {
        // @ts-ignore
        element.webkitRequestFullscreen();
        // @ts-ignore
      } else if (element.msRequestFullscreen) {
        // @ts-ignore
        element.msRequestFullscreen();
        // @ts-ignore
      } else if (element.mozRequestFullScreen) {
        // @ts-ignore
        element.mozRequestFullScreen();
      }
    }
  };

  const toggleFullscreen = async () => {
    const element = containerRef?.current;
    if (!element) {
      return;
    }
    if (document) {
      const isFullscreen = document.fullscreen;
      if (isFullscreen) {
        setFullscreen(false);
        await document.exitFullscreen();
        return;
      }
    }
    handleEnterFullScreen(element);
    setFullscreen(!fullscreen);
  };
  return (
    <IconContainer onPress={toggleFullscreen}>
      <FullscreenIcon />
    </IconContainer>
  );
};

export default Fullscreen;

const IconContainer = styled.Pressable({
  marginLeft: spacing(1.5),
});
