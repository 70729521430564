import {memo, useCallback, useRef, useState} from 'react';
import type {StateCallBack} from 'react-multi-carousel';
import Carousel from 'react-multi-carousel';
import {Text} from 'react-native-paper';
import styled, {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {useTheme} from '@emotion/react';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import {useUpdateAtom} from 'jotai/utils';

import Button from '@src/components/atoms/Button';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

import {IntroCompletedAtom} from './store';

import 'react-multi-carousel/lib/styles.css';
import './style.css';

type mainScreenProp = StackNavigationProp<RootStackParamList, 'Main'>;

type Props = {
  data: Array<{
    description: string;
    image: number;
    key: number;
    title: string;
  }>;
};

const Pagination = ({data}: Props) => {
  const setCompleted = useUpdateAtom(IntroCompletedAtom);
  const ref = useRef<Carousel>(null);
  const theme = useTheme();
  const [pageIndex, setPageIndex] = useState(0);
  const {navigate} = useNavigation<mainScreenProp>();

  const buttonHandler = () => {
    const newPageIndex = pageIndex + 1;
    setPageIndex(newPageIndex);
    ref.current?.next(1);
  };

  const buttonNavigate = () => {
    setCompleted(true);
    navigate('Main');
  };

  const handlePageChange = useCallback(
    (previousSlide: number, state: StateCallBack) => {
      setPageIndex(state.currentSlide);
    },
    [],
  );

  return (
    <PaginationView>
      <CardsArea>
        <Carousel
          ref={ref}
          slidesToSlide={pageIndex}
          showDots
          dotListClass={'dot-carousel'}
          containerClass={'carousel'}
          itemClass={'carousel'}
          sliderClass={'carousel'}
          arrows={false}
          afterChange={handlePageChange}
          responsive={{
            tablet: {
              breakpoint: {
                max: 3160,
                min: 0,
              },
              items: 1,
            },
          }}>
          {data.map(item => (
            <Card key={item.key}>
              <ImageContainer>
                <CImage
                  resizeMode="contain"
                  source={item.image}
                  key={item.image}
                />
              </ImageContainer>

              <DescriptionContainer key={item.description}>
                <Text
                  variant="titleSmall"
                  style={descriptionStyle(theme)}
                  key={item.description}>
                  {item.description}
                </Text>
              </DescriptionContainer>
            </Card>
          ))}
        </Carousel>
      </CardsArea>
      {pageIndex >= 2 ? (
        <CButton onPress={buttonNavigate} mode="contained">
          {translations.buttons.understand}
        </CButton>
      ) : (
        <CButton onPress={buttonHandler} mode="contained">
          {translations.buttons.goAhead}
        </CButton>
      )}
    </PaginationView>
  );
};

export default memo(Pagination);

const CImage = styled.Image`
  width: 100%;
  height: 100%;
  max-width: 200px;
`;

const CButton = styled(Button)({marginTop: spacing(3)});
const Card = styled.View({flex: 1, height: '100%'});
const CardsArea = styled.View({flex: 1});

const PaginationView = styled.View({
  flex: 1,
});
const ImageContainer = styled.View({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
});

const DescriptionContainer = styled.View({
  flex: 0.6,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginHorizontal: 22,
});

const descriptionStyle = (theme: Theme) =>
  css({
    color: theme.colors.onBackground,
    textAlign: 'center',
  });
