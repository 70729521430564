import {authExchange} from '@urql/exchange-auth';
import {useAtomValue} from 'jotai';
import {
  cacheExchange,
  Client,
  fetchExchange,
  Provider,
  subscriptionExchange,
} from 'urql';

import {accessTokenAtom} from '@src/auth/store';

type Props = {children: React.ReactNode};
import {createClient as createWSClient} from 'graphql-ws';

const wsClient = createWSClient({
  url: 'wss://web-mafia-dev.apps.ir-thr-ba1.arvanpaas.ir/graphql/query',
  shouldRetry: () => true,
});
const QueryClientProvider = ({children}: Props) => {
  const accessToken = useAtomValue(accessTokenAtom);
  const customAuthExchange = authExchange(async utils => ({
    addAuthToOperation: operation => {
      if (!accessToken) {
        return operation;
      }
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${accessToken}`,
      });
    },
    didAuthError: (error, _operation) =>
      error.graphQLErrors.some(e => e.extensions?.code === 'FORBIDDEN'),
    // ...
    refreshAuth: async () => {},
  }));

  const client = new Client({
    url: 'https://web-mafia-dev.apps.ir-thr-ba1.arvanpaas.ir/graphql/query',
    fetchSubscriptions: false,
    exchanges: [
      cacheExchange,
      customAuthExchange,
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: request => {
          const input = {...request, query: request.query || ''};
          return {
            subscribe: sink => {
              const unsubscribe = wsClient.subscribe(input, sink);
              return {unsubscribe};
            },
          };
        },
      }),
    ],
  });
  return <Provider value={client}>{children}</Provider>;
};

export default QueryClientProvider;
