import {Image, Pressable} from 'react-native';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';

import BuyIcon from '@src/assets/icons/buyBullet.svg';
import CupIcon from '@src/assets/icons/goldenCup.svg';
import BulletsCard from '@src/components/atoms/BulletsCard';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type UserDetailsProps = {
  totalScore?: number;
  remainingVotes?: number;
};

type ScreenProp = StackNavigationProp<RootStackParamList, 'Main'>;

const UserDetails = ({totalScore, remainingVotes}: UserDetailsProps) => {
  const {navigate} = useNavigation<ScreenProp>();

  const onPress = () => {
    navigate('Basket');
  };
  return (
    <Card>
      <CImage source={require('@src/assets/images/homeCard.png')} />
      <ContentCard>
        <BulletCard>
          <Title variant="labelMedium">
            :{translations.common.bulletNumber}
          </Title>
          <BulletSection>
            <BulletsCard bullets={Number(remainingVotes)} />
            <BuyBulletContainer onPress={onPress}>
              <BuyIcon />
            </BuyBulletContainer>
          </BulletSection>
        </BulletCard>
        <BulletCard>
          <Title variant="labelMedium">:{translations.common.totalScore}</Title>
          <BulletRow>
            <CupIcon />
            <ScoreText variant="headlineLarge">
              {digitsEnToFa(totalScore || 0)}
            </ScoreText>
          </BulletRow>
        </BulletCard>
      </ContentCard>
    </Card>
  );
};

export default UserDetails;

const Card = styled.View({
  borderRadius: 12,
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
  width: 220,
  height: 150,
  padding: spacing(1.5),
});

const CImage = styled(Image)({
  minWidth: 220,
  width: '100%',
  height: 150,
  position: 'absolute',
  top: 0,
  flex: 1,
  left: 0,
});

const ContentCard = styled.View({
  position: 'relative',
  zIndex: 1,
});

const Title = styled(Text)(({theme}) => ({
  color: theme.colors.onBackground,
  marginTop: spacing(0.5),
  textAlign: 'right',
}));

const ScoreText = styled(Text)(({theme}) => ({
  color: theme.colors.primary,
  marginTop: spacing(0.5),
  marginLeft: spacing(0.5),
}));

const BulletCard = styled.View({
  width: '100%',
});

const BulletRow = styled.View({flexDirection: 'row', alignItems: 'center'});

const BulletSection = styled.View({
  flexDirection: 'row',
});

const BuyBulletContainer = styled(Pressable)({
  position: 'absolute',
  right: 0,
  top: 10,
});
