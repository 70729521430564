import {useCallback} from 'react';
import {useSetAtom} from 'jotai';

import {
  accessTokenAtom,
  profileAtom,
  refreshTokenAtom,
  storeAccessToken,
} from '@src/auth/store';

const useLogout = () => {
  const setAccessToken = useSetAtom(accessTokenAtom);
  const setRefreshToken = useSetAtom(refreshTokenAtom);
  const setProfile = useSetAtom(profileAtom);

  return useCallback(async () => {
    setProfile(undefined);
    setAccessToken(undefined);
    await setRefreshToken(undefined);

    storeAccessToken(undefined);
  }, [setAccessToken, setProfile, setRefreshToken]);
};

export default useLogout;
