import {ActivityIndicator} from 'react-native-paper';
import styled from '@emotion/native';

const Loading = () => <CActivityIndicator size={'large'} />;

export default Loading;

const CActivityIndicator = styled(ActivityIndicator)({
  flex: 1,
  margin: 'auto',
});
