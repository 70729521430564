import type {NativeStackScreenProps} from 'react-native-screens/native-stack';
import styled from '@emotion/native';

import Loading from '@src/components/atoms/Loading';
import type {Game, MyGames, UserGameScoreQuery} from '@src/data/generated';
import {
  useMyGameVoteDetailsQuery,
  usePlayedGameQuery,
  useUserGameScoreQuery,
} from '@src/data/generated';
import type {RootStackParamList} from '@src/navigation/RootStack';

import Header from './Header';
import Main from './Main';

type Props = NativeStackScreenProps<RootStackParamList, 'PlayedEpisode'>;

const PlayedEpisodeScreen = ({route: {params}}: Props) => {
  const gameID = params.id;

  const [{data, fetching}] = usePlayedGameQuery({
    variables: {id: gameID},
  });
  const [{data: userDetails, fetching: userFetching}] = useUserGameScoreQuery();
  const [{data: gameVotesDetails}] = useMyGameVoteDetailsQuery({
    variables: {gameId: gameID},
  });
  return (
    <>
      {fetching && userFetching ? (
        <Loading />
      ) : (
        <Container>
          <Header game={data?.game as Game} />
          <Main
            game={data?.game as Game}
            userDetails={userDetails as UserGameScoreQuery}
            gameVotesDetails={gameVotesDetails?.myGames?.[0] as MyGames}
          />
        </Container>
      )}
    </>
  );
};

export default PlayedEpisodeScreen;

const Container = styled.ScrollView({
  flex: 1,
});
