import {Text} from 'react-native-paper';
import styled from '@emotion/native';

import Information from '@src/components/screens/Live/Main/Information';
import Players from '@src/components/screens/Live/Main/Players';
import type {Game} from '@src/data/generated';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type Props = {
  game?: Game;
};

const Main = ({game}: Props) => (
  <MainContainer>
    <Information game={game} />
    <PlayersText variant="bodyLarge">{translations.common.players}</PlayersText>
    <CardContainer>
      {game?.players?.map((item, index) => (
        <Players data={item} key={index} />
      ))}
    </CardContainer>
  </MainContainer>
);

export default Main;

const MainContainer = styled.View({
  paddingHorizontal: spacing(4),
  paddingBottom: spacing(3),
});

const CardContainer = styled.View({
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingBottom: spacing(12),
});
const PlayersText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  alignSelf: 'flex-end',
  marginTop: spacing(3),
}));
