import {View} from 'react-native';
import {Avatar, Text} from 'react-native-paper';
import styled, {css} from '@emotion/native';

import BronzeCrown from '@src/assets/icons/bronzCrown.svg';
import GoldCrown from '@src/assets/icons/goldCrown.svg';
import GrayCrown from '@src/assets/icons/grayCrown.svg';
import SilverCrown from '@src/assets/icons/silverCrown.svg';
import TrophyIcon from '@src/assets/icons/trophy.svg';
import {spacing} from '@src/theme/units';

type rowProps = {
  rank: number;
  name: string;
  score: number;
  avatar: any;
};

const Row = ({rank, name, score, avatar}: rowProps) => (
  <Container>
    <TrophyContainer>
      <View style={trophyStyle}>
        <TrophyIcon />
      </View>
      <TrophyNumber>{score.toLocaleString('fa')}</TrophyNumber>
    </TrophyContainer>

    <Avatar.Image size={24} source={avatar} style={avatarStyle} />
    <View style={playerDetailStyle}>
      <PlayerName>{name}</PlayerName>
      <CrownContainer>
        {rank === 1 ? (
          <GoldCrown />
        ) : rank === 2 ? (
          <SilverCrown />
        ) : rank === 3 ? (
          <BronzeCrown />
        ) : (
          <GrayCrown />
        )}
        <RankText variant="labelMedium">{rank.toLocaleString('fa')}</RankText>
      </CrownContainer>
    </View>
  </Container>
);

export default Row;

const Container = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: spacing(2),
});

const TrophyContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: 70,
});

const trophyStyle = css({width: 15});

const TrophyNumber = styled(Text)(props => ({
  marginLeft: spacing(3),
  color: props.theme.colors.onBackground,
  marginTop: spacing(0.5),
  width: 50,
}));

const avatarStyle = css({
  backgroundColor: 'transparent',
});

const PlayerName = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  flex: 1,
  textAlign: 'right',
  marginTop: spacing(0.5),
  marginRight: spacing(1),
}));

const playerDetailStyle = css({
  flexDirection: 'row',
});
const CrownContainer = styled.View({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
const RankText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
  position: 'absolute',
  top: 8,
}));
