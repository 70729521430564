import type {ReactNode} from 'react';
import {memo, useCallback, useEffect, useRef} from 'react';
import {isSafari} from 'react-device-detect';
import type {View} from 'react-native';
import {Pressable} from 'react-native';
import ReactPlayer from 'react-player';
import styled, {css} from '@emotion/native';
import {useAtom, useAtomValue} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import Gradient from '@src/assets/icons/gradientBorromTop.svg';
import CustomControls from '@src/components/molocules/Player/controlls';
import {
  bufferingAtom,
  containerRefAtom,
  durationAtom,
  fullscreenAtom,
  isPlayingAtom,
  muteAtom,
  playerRefAtom,
  progressAtom,
  showControlsAtom,
  volumeAtom,
} from '@src/components/molocules/Player/controls.store';

import Thumbnail from './controlls/Thumbnail';

type Props = {
  url: string;
  thumbnail?: string;
  isLive?: boolean;
  showControls?: boolean;
  children?: ReactNode;
};

// CustomPlayer Component
const Player = memo(({url, thumbnail, isLive = false, children}: Props) => {
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom);
  const fullscreen = useAtomValue(fullscreenAtom);
  const volume = useAtomValue(volumeAtom);
  const mute = useAtomValue(muteAtom);
  const setBuffering = useUpdateAtom(bufferingAtom);
  // const {updateSnackbar} = useSnackbar();
  const [progress, setProgress] = useAtom(progressAtom);
  const setDuration = useUpdateAtom(durationAtom);
  const setPlayerRef = useUpdateAtom(playerRefAtom);
  const setContainerRef = useUpdateAtom(containerRefAtom);
  const [showControls, setShowControls] = useAtom(showControlsAtom);

  const playerRef = useRef<ReactPlayer | null>(null);
  const containerRef = useRef<View | null>(null);

  const toggleBuffer = useCallback(
    (isBuffer: boolean) => {
      setBuffering(isBuffer);
    },
    [setBuffering],
  );

  useEffect(() => {
    if (playerRef && containerRef) {
      setPlayerRef(playerRef);
      setContainerRef(containerRef);
    }
  }, [setContainerRef, setPlayerRef]);

  const handleError = (e: any) => {
    console.log('player Error: ', e);
  };

  return (
    <Pressable
      onPress={() => setShowControls(e => !e)}
      ref={containerRef}
      style={isSafari && fullscreen ? iosFullscreenStyle : containerStyle}>
      <ReactPlayer
        onError={handleError}
        ref={playerRef}
        url={url}
        width="100%"
        height="100%"
        onBuffer={() => toggleBuffer(true)}
        playsinline
        volume={volume / 100}
        muted={mute}
        onReady={() => toggleBuffer(false)}
        onClickPreview={() => setIsPlaying(true)}
        onBufferEnd={() => toggleBuffer(false)}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onProgress={value => setProgress(value.playedSeconds || 0)}
        onDuration={value => setDuration(value || 0)}
        playing={isPlaying}
      />

      {/* ThumbNail */}
      {!isPlaying && progress === 0 && <Thumbnail uri={thumbnail} />}

      <>
        {/* Children */}
        {showControls && children}
        {/* Control */}
        {showControls && <CustomControls isLive={isLive} />}
        {/* Gradient */}
        <GradientContaner>
          <Gradient />
        </GradientContaner>
      </>
    </Pressable>
  );
});

export default Player;

const containerStyle = css`
  width: 100%;
  height: fit-content;
  position: relative;
  margin: auto;
`;

// @ts-ignore
const iosFullscreenStyle = css({
  height: '100vw',
  maxHeight: 450,
  width: '100vh',
  position: 'absolute',
  top: 0,
  bottom: 0,
  transform: 'rotate(90deg)',
  margin: 'auto',
});

const GradientContaner = styled.View({
  position: 'absolute',
  width: '100%',
  height: 120,
  zIndex: 1,
  bottom: 0,
  left: 0,
  right: 0,
});
