import {useCallback} from 'react';
import {Pressable} from 'react-native';
import {Avatar, Text} from 'react-native-paper';
import styled, {css} from '@emotion/native';
import type {Theme} from '@emotion/react';
import {useTheme} from '@emotion/react';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';

import EditIcon from '@src/assets/icons/pen.svg';
import {useProfileQuery} from '@src/data/generated';
import type {RootStackParamList} from '@src/navigation/RootStack';
import {spacing} from '@src/theme/units';

type SignupRouteProps = StackNavigationProp<RootStackParamList, 'SignUp'>;

const Profile = () => {
  const theme = useTheme();
  const {navigate} = useNavigation<SignupRouteProps>();
  const onPress = useCallback(() => {
    navigate('SignUp');
  }, [navigate]);
  const [{data: profileData}] = useProfileQuery();

  return (
    <Container>
      <ImageComponent onPress={onPress}>
        <Avatar.Image
          style={imageStyle(theme)}
          size={96}
          source={
            profileData?.profile.avatar &&
            require(`@src/assets/avatars/${profileData?.profile.avatar}.png`)
          }
        />
        <EditIconContainer>
          <EditIcon />
        </EditIconContainer>
        <PlayerName>{profileData?.profile.firstName}</PlayerName>
      </ImageComponent>
    </Container>
  );
};

export default Profile;

const Container = styled.View({
  alignItems: 'center',
  marginBottom: spacing(3),
});

const imageStyle = (theme: Theme) =>
  css({
    backgroundColor: theme.colors.surface,
  });
const ImageComponent = styled(Pressable)({});

const EditIconContainer = styled.View({
  position: 'absolute',
  top: 70,
});

const PlayerName = styled(Text)(props => ({
  fontSize: 20,
  marginTop: spacing(1),
  textAlign: 'center',
  color: props.theme.colors.onBackground,
}));
