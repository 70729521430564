import {Platform} from 'react-native';
import type {MD3Theme} from 'react-native-paper';

const typeface = {
  brandRegular: Platform.select({
    web: 'Yekan, "Helvetica Neue", Helvetica, Arial, sans-serif',
    ios: 'Yekan-Regular',
    default: 'Yekan-Regular',
  }),
  weightRegular: 'normal' as MD3Theme['fonts']['default']['fontWeight'],

  plainMedium: Platform.select({
    web: 'Yekan, "Helvetica Neue", Helvetica, Arial, sans-serif',
    ios: 'Yekan-Medium',
    default: 'Yekan-Medium',
  }),
  weightMedium: '500' as MD3Theme['fonts']['default']['fontWeight'],
};

const regularType = {
  fontFamily: typeface.brandRegular,
  letterSpacing: 0,
  fontWeight: typeface.weightRegular,
};

const mediumType = {
  fontFamily: typeface.plainMedium,
  letterSpacing: 0.15,
  fontWeight: typeface.weightMedium,
};

const fonts: MD3Theme['fonts'] = {
  displayLarge: {
    ...regularType,
    lineHeight: 64,
    fontSize: 57,
  },
  displayMedium: {
    ...regularType,
    lineHeight: 52,
    fontSize: 45,
  },
  displaySmall: {
    ...regularType,
    lineHeight: 44,
    fontSize: 36,
  },

  headlineLarge: {
    ...regularType,
    lineHeight: 40,
    fontSize: 32,
  },
  headlineMedium: {
    ...regularType,
    lineHeight: 36,
    fontSize: 28,
  },
  headlineSmall: {
    ...regularType,
    lineHeight: 32,
    fontSize: 18,
  },

  titleLarge: {
    ...regularType,
    lineHeight: 24,
    fontSize: 22,
  },
  titleMedium: {
    ...mediumType,
    lineHeight: 24,
    fontSize: 16,
  },
  titleSmall: {
    ...mediumType,
    letterSpacing: 0.1,
    lineHeight: 20,
    fontSize: 14,
  },

  labelLarge: {
    ...mediumType,
    letterSpacing: 0.1,
    lineHeight: 20,
    fontSize: 14,
  },
  labelMedium: {
    ...mediumType,
    letterSpacing: 0.5,
    lineHeight: 16,
    fontSize: 12,
  },
  labelSmall: {
    ...mediumType,
    letterSpacing: 0.5,
    lineHeight: 16,
    fontSize: 11,
  },

  bodyLarge: {
    ...mediumType,
    fontWeight: typeface.weightRegular,
    fontFamily: typeface.brandRegular,
    lineHeight: 24,
    fontSize: 16,
  },
  bodyMedium: {
    ...mediumType,
    fontWeight: typeface.weightRegular,
    fontFamily: typeface.brandRegular,
    letterSpacing: 0.25,
    lineHeight: 20,
    fontSize: 14,
  },
  bodySmall: {
    ...mediumType,
    fontWeight: typeface.weightRegular,
    fontFamily: typeface.brandRegular,
    letterSpacing: 0.4,
    lineHeight: 16,
    fontSize: 12,
  },

  default: {
    ...regularType,
  },
};

export default fonts;
