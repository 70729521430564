import Svg, {Path} from 'react-native-svg';

type Props = {color: string};

const HomeIcon = ({color}: Props) => (
  <Svg width="21" height="20" viewBox="0 0 21 20" fill="none">
    <Path
      d="M12.0031 1.19723L12.0034 1.19742L17.7633 5.22736C17.7633 5.22738 17.7633 5.2274 17.7634 5.22742C18.2326 5.55589 18.6768 6.09445 19.0043 6.72163C19.3317 7.34885 19.52 8.02201 19.52 8.59774V15.3777C19.52 17.6516 17.6739 19.4977 15.4 19.4977H4.62C2.34755 19.4977 0.5 17.643 0.5 15.3677V8.46774C0.5 7.9333 0.670387 7.29423 0.968575 6.6886C1.26663 6.08326 1.67107 5.55462 2.09752 5.22199L2.09762 5.22191L7.10625 1.31298C7.10647 1.3128 7.1067 1.31263 7.10692 1.31246C8.44376 0.277176 10.6128 0.223155 12.0031 1.19723ZM10.01 17.2477C10.6961 17.2477 11.26 16.6839 11.26 15.9977V12.9977C11.26 12.3116 10.6961 11.7477 10.01 11.7477C9.32386 11.7477 8.76 12.3116 8.76 12.9977V15.9977C8.76 16.6839 9.32386 17.2477 10.01 17.2477Z"
      stroke={color}
    />
  </Svg>
);
export default HomeIcon;
