import 'normalize.css';
import '@src/assets/css/fonts.css';
import '@src/assets/css/index.css';

import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import App from '@src/App';
import {appName, sentryDsn, sentryTracesSampleRate} from '@src/configuration';
import reportWebVitals from '@src/sw/reportWebVitals';
import * as serviceWorkerRegistration from '@src/sw/serviceWorkerRegistration';
import {createRoot} from 'react-dom/client';
import {AppRegistry} from 'react-native';
import {enableExperimentalWebImplementation} from 'react-native-gesture-handler';

enableExperimentalWebImplementation(true);

if (!__DEV__) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: sentryTracesSampleRate,
  });
}

AppRegistry.registerComponent(appName, () => App);
const rootTag = createRoot(document.getElementById('root'));

rootTag.render(<App />);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
