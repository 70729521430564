import {useCallback, useState} from 'react';
import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';
import {useNavigation} from '@react-navigation/native';
import type {StackNavigationProp} from '@react-navigation/stack';
import {useAtomValue} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';

import {
  accessTokenAtom,
  otpAtom,
  refreshTokenAtom,
  usernameAtom,
} from '@src/auth/store';
import BackButton from '@src/components/atoms/BackButton';
import Container from '@src/components/atoms/Container';
import StatusBar from '@src/components/atoms/StatusBar';
import {
  useLoginMutation,
  useProfileQuery,
  useRequestVerificationCodeMutation,
} from '@src/data/generated';
import useSnackbar from '@src/hooks/useSnackbar';
import type {RootStackParamList} from '@src/navigation/RootStack';
import translation from '@src/translations';

import OTPInput from './OTPInput';
import Resend from './Resend';
import TimeCounter from './TimeCounter';

type OtpScreenProp = StackNavigationProp<RootStackParamList, 'OTP'>;

const OTP = () => {
  // const {setMessage} = useSnackbar();
  const navigation = useNavigation<OtpScreenProp>();
  const [code, setCode] = useState('');
  const {updateSnackbar} = useSnackbar();

  const [, setLogin] = useLoginMutation();
  const [, sendRequest] = useRequestVerificationCodeMutation();

  const setAccessToken = useUpdateAtom(accessTokenAtom);
  const setRefreshToken = useUpdateAtom(refreshTokenAtom);
  const setOtp = useUpdateAtom(otpAtom);
  const username = useAtomValue(usernameAtom);

  const [{data: profileData}, refetchProfileData] = useProfileQuery({
    pause: false,
  });
  const handleResent = useCallback(async () => {
    try {
      setOtp(true);
      await sendRequest({phoneNumber: username});
      updateSnackbar(
        translation.common.successfullySent,
        translation.common.codeSent,
      );
    } catch (e: any) {
      updateSnackbar(translation.errors.error, e.errors[0].message);
    }
  }, [sendRequest, setOtp, updateSnackbar, username]);

  const handleCodeFilled = useCallback(
    async (filled: string) => {
      try {
        const result = await setLogin({code: filled, phoneNumber: username!});
        if (!result.error && result.data) {
          setAccessToken(result.data.login.accessToken);
          setRefreshToken(result.data.login.refreshToken);
          setOtp(false);
          refetchProfileData();
          const profile = await profileData;
          if (!profile?.profile.avatar) {
            navigation.navigate('SignUp');
          } else {
            navigation.navigate('Main');
          }
        } else if (result.error) {
          updateSnackbar(translation.errors.error, result.error.message);
        }
      } catch (e: any) {
        updateSnackbar(translation.errors.error, e.errors[0].message);
      }
    },
    [
      navigation,
      profileData,
      refetchProfileData,
      setAccessToken,
      setLogin,
      setOtp,
      setRefreshToken,
      updateSnackbar,
      username,
    ],
  );

  return (
    <SafeArea>
      <StatusBar />
      <Container>
        <BackButton />
        <TextContainer>
          <TimeCounter />
          <Body variant="bodyMedium">{`${
            translation.common.enterCode_1
          } ${digitsEnToFa(Number(username))} ${
            translation.common.enterCode_2
          }`}</Body>
        </TextContainer>
        <OTPInput
          code={code}
          onCodeChanged={setCode}
          onCodeFilled={handleCodeFilled}
        />
        <Resend onResend={handleResent} />
      </Container>
    </SafeArea>
  );
};

export default OTP;

const SafeArea = styled.SafeAreaView`
  flex: 1;
`;

const TextContainer = styled.View``;

const Body = styled(Text)`
  text-align: center;
  margin-top: 34px;
  margin-bottom: 30px;
  color: ${({theme}) => theme.colors.onSurfaceDisabled};
`;
