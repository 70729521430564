import {Text} from 'react-native-paper';
import styled from '@emotion/native';
import {digitsEnToFa} from '@persian-tools/persian-tools';

import type {Game} from '@src/data/generated';
import {spacing} from '@src/theme/units';
import translations from '@src/translations';

type Props = {
  game?: Game;
};

const Information = ({game}: Props) => (
  <>
    <TitleContainer>
      <EpisodeNumber variant={'titleLarge'}>
        {digitsEnToFa(game?.episode ?? 0)}
      </EpisodeNumber>
      <EpisodeText variant={'titleLarge'}>
        {translations.common.episode}
      </EpisodeText>
      <EpisodeText variant={'titleLarge'}>- {game?.title}</EpisodeText>
    </TitleContainer>
    <DescriptionText variant="labelMedium">{game?.description}</DescriptionText>
  </>
);
export default Information;

const TitleContainer = styled.View({
  flexDirection: 'row',
  margin: spacing(4),
  marginRight: spacing(0),
  marginBottom: spacing(2),
  justifyContent: 'flex-end',
});

const EpisodeText = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));

const DescriptionText = styled(Text)(props => ({
  textAlign: 'right',
  marginTop: spacing(3),
  color: props.theme.colors.outlineVariant,
}));

const EpisodeNumber = styled(Text)(props => ({
  color: props.theme.colors.onBackground,
}));
